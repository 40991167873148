import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

function RequireAuth({ children, accessToken }) {
  const location = useLocation();

  return accessToken ? (
    children
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(RequireAuth);
