import React, { useContext } from "react";
import Footer from "./Landing/Footer/Footer";
import Nav from "./Landing/Nav/Nav";
import { Box, Text } from "@chakra-ui/react";
import { ThemeContext } from "Context/ThemeContext";

export default function BillingSuccessPage() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box>
      <Nav/>

      <Box color='#fff' textAlign='center'>
        <Text fontSize='3xl' my='8'>Payment Successful</Text>
        {/* <p style={{marginTop: 50, marginBottom: 10, fontWeight: 600, fontSize: "3.25rem", color: "#101010"}}>Payment Successful</p> */}
        <img style={{display: "block", margin: "auto", maxWidth: 500}} src={require("../Images/sparkles.gif")} />

        <Box my='12'>
          <a href="/dashboard" style={{ fontWeight: 500, color: theme.primary }}>← Back to my dashboard</a>
        </Box>    
      </Box>

      <Footer/>
    </Box>
  );
}
