import React, { useContext, useState } from "react";
import { createCheckoutSessionAPI, createPortalSessionAPI } from "../../lib/API/billingAPI"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaGhost} from "react-icons/fa";
import { SiGhost, } from "react-icons/si";
import { GiGhost } from "react-icons/gi";
import { RiGhost2Line, RiGhostSmileLine } from "react-icons/ri";

import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import { Box, Button, Flex, Image, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { MdCheck, MdPlusOne } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { BiLoaderCircle, BiStopCircle } from "react-icons/bi";
import './Plan.css';
import { ThemeContext } from "Context/ThemeContext";

export default function Plan({planId, name, yearly, monthly, description, currencySymbol, index, displayYearly}) {
  const { theme } = useContext(ThemeContext);
  let navigate = useNavigate();
  const accessToken = useSelector((state) => state.accessToken);
  const [loading, setLoading] = useState(false);
  const billing = useSelector((state) => state.billing);
  const icons = [SiGhost, RiGhostSmileLine, RiGhost2Line, GiGhost]
  const Icon = icons[index];

  const imagePlansMonthly = [
    { title: '100 clones', description: '0.29p per clone'},
    { title: '500 clones', description: '0.19p per clone'},
    { title: '10,000 clones', description: '0.04p per clone'},
    { title: '10,000 clones', description: '0.10p per clone'}
  ]

  const imagePlansAnnually = [
    { title: '100 clones', description: '0.23p per clone'},
    { title: '500 clones', description: '0.15p per clone'},
    { title: '10,000 clones', description: '0.03p per clone'},
    { title: '10,000 clones', description: '0.08p per clone'}
  ]

  const isCurrentPlan = () => {
    return billing.currentPlanId === planId && (billing.interval === "year" && displayYearly || billing.interval === "month" && !displayYearly)
  }

  const isPremium = name?.toLowerCase() == 'premium';
  const highlightPlan = isCurrentPlan() || isPremium;
  const currentPlan = isCurrentPlan();

  const colors = [
    {background: "linear-gradient(135deg, #C4C4C4 10%, #A78ABD 100%)", color: "#C4C4C4"},
    {background: "linear-gradient(135deg, #FCCF31 10%, #F55555 100%)", color: "#FCCF31"},
    {background: "linear-gradient(135deg, #F761A1 10%, #8C1BAB 100%)", color: "#F761A1"},
    {background: "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)", color: "#43CBFF"}
  ]

  // get button type, upgrade, downgrade or current plan
  const getButtonText = () => {
    if (billing.currentPlanId === 0 && planId == 0) return 'Get started for free';
    // if (!billing.isExistingStripeCustomer && billing.currentPlanId === 0) return "👉🏻 Start your free trial"
    else if (planId < billing.currentPlanId || billing.interval === "year" && !displayYearly) return "Downgrade"
    else if (planId > billing.currentPlanId || billing.interval  === "month" && displayYearly) return "Upgrade"
    else return "Cancel"
  }

  const onButtonClickHandler = async (isStarterSelected = false) => {
    if (isStarterSelected) {
      return navigate("/auth/register") 
    }

    setLoading(true)
    if (!accessToken) {
      navigate("/auth/register") 
      return
    }

    // if not on a plan, create checkout session with the price/product
    if (billing.currentPlanId === 0) {
      const res = await createCheckoutSessionAPI(displayYearly ? yearly.priceId : monthly.priceId)

      if (res.data.success) {
          window.open(res.data.url, "_self")
      } else {
          toast.error(res.data.error)
      }
    // if already on a plan then open portal page
    } else {
      const res = await createPortalSessionAPI()

      if (res.data.success) {
          window.open(res.data.url, "_self")
      } else {
          toast.error(res.data.error)
      }
    }
    setLoading(false)
  }

  return (
    <Box bg='#262626' minW='300px' _hover={{ boxShadow: '2xl', transform: 'scale(1.05)' }} transition="all 0.3s ease-in-out" mb={10} position='relative' textAlign='center' px={6} py='10' border={currentPlan || (planId === 3 && billing.currentPlanId === 0) ? `2px solid ${theme.primary}` : "2px solid #555"} boxShadow='lg' rounded='xl'>
      { (planId === 3 && billing.currentPlanId !== 2 && !currentPlan) &&
        <Box position='absolute' top='-15px' left='90px' rounded='full' overflow='hidden' bg={theme.primary}>
          <Text fontSize='xs' py={1} px={2} color='#1a1a1a' fontWeight={700}>MOST POPULAR</Text>
        </Box>
      }

      { currentPlan &&
        <Box position='absolute' top='-15px' left='90px' rounded='full' overflow='hidden' bg={theme.primary}>
          <Text fontSize='xs' py={1} px={2} color='#1a1a1a' fontWeight={700}>CURRENT PLAN</Text>
        </Box>
      }

      <Text fontSize='2xl' fontWeight={700} color='#fff' mb='4'>{name}</Text>

      { Icon &&
        <Box boxShadow='xl' width='fit-content' p={5} rounded='full' m='auto' mb={4}>
          <Icon size={30} color={colors[index].color} />
        </Box>
      }

      <h1 class="service__price" style={{color: theme.primary}}>
        { planId !== 0 &&
          <sup class="currency">{currencySymbol}</sup>
        }
        { displayYearly ? Math.floor(yearly.amount/12) : monthly.amount}
        <span style={{fontSize: 20, fontWeight: 600}}>/mo</span>
      </h1>

      <Text mt='4' color='#fff' fontSize='xl' fontWeight={500}>{displayYearly ? imagePlansAnnually[index].title : imagePlansMonthly[index].title}</Text>
      <Text mt='2' color='#38B6FF' fontSize='md' fontWeight={500}>{displayYearly ? imagePlansAnnually[index].description : imagePlansMonthly[index].description}</Text>

      <Button mt={6} onClick={() => onButtonClickHandler(planId == 0 ? true : false)} _hover={{ bg: theme.hover, color: 'white' }} bg={(planId === 3 && billing.currentPlanId === 0) ? theme.primary : 'none'} size='md' color={(planId === 3 && billing.currentPlanId === 0) ? '#262626' : theme.primary } borderWidth={2} borderColor={theme.primary} fontWeight={500} width='100%'>
        {!loading && getButtonText()}
        {loading && <div style={{marginTop: 5}}><ClipLoader size={25} color={isPremium ? "white" : theme.primary} loading={true}/></div>}
      </Button>
    </Box>
  )
}