import { createStore } from "redux";
import { persistStore } from 'redux-persist'
import rootReducer from './reducers/rootReducer'
import { composeWithDevTools } from "redux-devtools-extension";
import { userInitialStore, billingInitialStore } from "./initialStores"
import { notificationInitialStore } from "./notificationsRedux/notificationUtils"
import { brandingInitialStore } from "./branding/brandingUtils"


const initialReduxStore = {
    accessToken: null,
    user: userInitialStore,
    billing: billingInitialStore,
    notification: notificationInitialStore,
    collapseSidebar: false,
    branding: brandingInitialStore
}

const store = createStore(
    rootReducer,
    initialReduxStore,
    composeWithDevTools()
)

let rehydrationComplete;
let rehydrationFailed;

const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
  rehydrationFailed = reject;
});

export function rehydration () {
  return rehydrationPromise;
};

const getWebPersistor = () => {
  return persistStore(store)
}

const getMobilePersistor = () => {
  const persistor = persistStore(store, null, () => {
    rehydrationComplete();
  });

  return persistor
}

const persistor = process.env.REACT_APP_WEB === 'true' ? getWebPersistor() : getMobilePersistor()

const obj = { store, persistor }

export default obj