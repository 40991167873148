const store = {};

const Theme = () => {
  return colors.blue;

  //TODO:: Add logic here
  if (store.color) {
    return colors[state.color];
  } else {
    return colors.blue;
  }
};

const colors = {
  blue: {
    primary: "#FECD1A",
    hover: "#e4b301",
    gradient: "#1a1a1a",
  },
  purple: {
    primary: "#6550F6",
    hover: "#563df5",
  },
  red: {
    primary: "#FF1616",
    hover: "#ff3333",
  },
  orange: {
    primary: "#ff8133",
    hover: "#ff6200",
  },
};

export default Theme;
