import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { Flex, Box, FormControl, FormLabel, Stack, Link, Button, Heading, Text, ButtonGroup, Center, HStack, PinInput, PinInputField, Image, Input } from '@chakra-ui/react';
import * as authHelper from "../../lib/helper/authHelper";
import InlineError from "../../Components/InlineError/InlineError";
import MetaTag from "../../Components/MetaTag";
import config from "../../lib/config/index"
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function LoginPage() {  
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();

  const accessToken = useSelector((state) => state.accessToken)

  const onSignInHandler = async (e) => {
    setLoading(true)
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const errorMessage = await authHelper.login(email, pwd)

    if (!errorMessage) {
      navigate(`/dashboard`, { replace: true });
    } else {
      setErrorMessage(errorMessage);
    }
    setLoading(false)
  }

  useEffect(() => {
    if (accessToken) {
      navigate(`/dashboard`, { replace: true });
    } 
  }, [])

  return (
    <Flex minH={'100vh'} paddingTop={{ base: 0, md: 12  }} align={{ base: 'flex-start', md: 'center' }} justify='center' bg='#1a1a1a'>
      <MetaTag title={`Login | Online Personal Training Software • ${config.app_name}`} />

      <Stack spacing={8} pb={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '3xl', md: '4xl' }} color='#fff'>Sign in</Heading>
          <Text fontSize={'lg'} color='#fff'>
            to continue cloning your images 👻
          </Text>
        </Stack>

        <Box rounded={'2xl'} bg='#262626' boxShadow={'lg'} p={8} as='form' onSubmit={onSignInHandler} shadow='0 0 25px 3px rgba(251, 191, 86, 0.5)'>
          <Stack spacing={4}>
            <Flex justify="center" mb="2">
              <Logo isLink />
            </Flex>


              <Box>
                <FormControl id="email">
                  <FormLabel color='#fff'>Email address</FormLabel>
                  <Input variant='outline' color='#fff' type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                </FormControl>

                <FormControl id="password" mt="6">
                  <FormLabel color='#fff'>Password</FormLabel>
                  <Input variant='outline' color='#fff' type="password" isPassword={true} onChange={(e) => setPwd(e.target.value)} value={pwd} />
                </FormControl>
              </Box>



            <Stack spacing={10}>
                <>
                  <Stack align={'flex-end'}>
                    <Link as={ReactLink} color={theme.primary} to="/auth/forgot-password">Forgot password?</Link>
                  </Stack>

                  <Button type={"submit"} bg={theme.primary} color={'#262626'} _hover={{ bg: theme.hover }} isLoading={loading}>Sign in</Button>
                </>

            </Stack>

            <Center>
              <InlineError text={errorMessage} />
            </Center>
          </Stack>
        </Box>

          <Box textAlign='center'>
            <Link as={ReactLink} color='#fff' to='/auth/register' fontWeight={600}>Don't have an account? Sign Up</Link>
          </Box>
      
      </Stack>
    </Flex>
  );
}