import { Box, Flex, Text, Image, IconButton, Menu, MenuButton, Button, Stack, Collapse, Icon, Link, Popover, PopoverTrigger, PopoverContent, useBreakpointValue, useDisclosure, SimpleGrid, Divider, PopoverArrow, MenuList, MenuItem, PopoverCloseButton, PopoverBody, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Link as ReactLink } from "react-router-dom";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useSelector } from "react-redux";
import Logo from 'Images/Logo';
import { FiArrowRight } from 'react-icons/fi';
import { FaListUl } from 'react-icons/fa';
import { Link as Scroll } from 'react-scroll';
import { useContext } from 'react';
import { ThemeContext } from 'Context/ThemeContext';

export default function Nav({ bg = false }) {
  const { theme } = useContext(ThemeContext);
  const { isOpen, onToggle } = useDisclosure();
  // const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box bg={bg ? bg : 'none'} zIndex={10} borderBottom={0} borderStyle='solid' borderColor='gray.200' py={{ base: 2, lg: 4 }} px={{ base: 4, lg: 14 }}>
      <Flex maxW={'7xl'} color='gray.600' minH='60px' py={{ base: 2 }} align='center' m='0 auto'>
        <Flex flex={{ base: 1 }} align='center'>
          <Flex align='center' as={ReactLink} to="/">
            <Logo theme={theme.primary} />
          </Flex>
          <Flex flex={1} justify='flex-end' mr='6' display={{ base: 'none', lg: 'flex' }} >
            <Link 
              as={Scroll}
              to="pricingSection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust this offset to your needs
              duration={500} // Animation duration in milliseconds
              fontSize='sm'
              fontWeight={500} 
              color='#fff'
               _hover={{ color: theme.primary }}
            >
              Pricing
            </Link>
          </Flex>
        </Flex>

        <Box display={{ base: 'none', lg: 'flex' }} >
          {renderLoginButtons()}
        </Box>
    
        <Flex display={{ base: 'flex', lg: 'none' }}>
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} color='#fff' />} variant={'outline'} aria-label={'Toggle Navigation'} />
                </PopoverTrigger>
            <PopoverContent width='90vw' mx='5vw'>
              <PopoverArrow />
              <PopoverBody>
                <MobileNav />
              </PopoverBody>
            </PopoverContent>
            </>
            )}
      </Popover>
        </Flex>
      </Flex>
    </Box>
  );
}

const getLoginButton = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Button as={ReactLink} to="/auth/login" _hover={{ textDecoration: 'underline', color: theme.hover }} color={theme.primary} display='inline-flex' fontSize={'sm'} fontWeight={600} letterSpacing={1} variant={'link'}>
      Sign In
    </Button>
  )
}

const getRegisterButton = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Button as={ReactLink} rounded='full' px={6} py={3} to="/auth/register" display='inline-flex' fontSize='sm' size='md' fontWeight={600} letterSpacing={1} color={'#1a1a1a'} bg={theme.primary} _hover={{ bg: theme.hover }} rightIcon={<FiArrowRight size='14px' />}>
      Get started
    </Button>
  )
}

const goToAppButton = (link, text = 'Dashboard', outline = false) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Button href={link} as={'a'} rounded='full' px={6} py={3} display='inline-flex' fontSize='sm' size='md' fontWeight={600} borderWidth={1} borderColor={theme.primary} color={outline ? theme.primary : '#1a1a1a'} bg={outline ? '#fff' : theme.primary} _hover={{ bg: theme.hover, color: '#fff' }}>
      {text}
    </Button>
  )
}

const renderLoginButtons = () => {
  const accessToken = useSelector((state) => state.accessToken);

  return (
    <>

      { !accessToken &&
        <Stack flex={{ base: 1, md: 0 }} justify={{ base: 'flex-start', lg: 'flex-end' }} direction={'row'} spacing={6}>
          {getLoginButton()}
          {getRegisterButton()}
        </Stack>
      }

      { accessToken &&
        goToAppButton("/dashboard")
      }
    </>
  )
}

const MobileNav = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Stack bg='white' p={4} display={{ lg: 'none' }}>
      <Link 
        as={Scroll}
        to="pricingSection"
        spy={true}
        smooth={true}
        offset={-70} // Adjust this offset to your needs
        duration={500} // Animation duration in milliseconds
        fontSize='md'
        fontWeight={500} 
        color='#1a1a1a'
          _hover={{ color: theme.primary }}
      >
        Pricing
      </Link>
      
      <Divider />

      <Box pt={2}>
        {renderLoginButtons()}
      </Box>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { theme } = useContext(ThemeContext);
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex py={2} as={Link} href={href ?? '#'} justify={'space-between'} align={'center'} _hover={{ textDecoration: 'none' }}>
        <Text fontWeight={500} fontSize={'sm'}>{label}</Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={'solid'} borderColor='gray.200' align={'start'}>
          {children &&
            children.map((child) => (
              <Link as={ReactLink} key={child.label} py={2} to={child.href} fontSize={'sm'} fontWeight={500}>
                <Flex align='center' justify='center' _groupHover={{ color: theme.primary }}>
                  <Icon as={child.Icon} mr={3} />
                  <Text fontSize={'sm'}>{child.label}</Text>
                </Flex>
                {/* <Text fontSize={'xs'}>{subLabel}</Text> */}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};