import * as React from "react";
import { Link } from "react-router-dom";

const Logo = ({ width = 200, isVariant = false, isLink = false }) => {
  if (isLink) {
    return (
      <Link to='/'>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          version="1"
          viewBox="0 0 750 150"
        >
          <defs>
            <clipPath id="c5cf099349">
              <path d="M35 25.344h82V107H35zm0 0"></path>
            </clipPath>
            <clipPath id="bbf7a55c39">
              <path d="M15 45h82v82.344H15zm0 0"></path>
            </clipPath>
          </defs>
          <g clipPath="url(#c5cf099349)">
            <path
              fill="#fecd1a"
              d="M101.7 106.941h-51a15.274 15.274 0 01-8.496-2.582 15.235 15.235 0 01-4.22-4.222 15.05 15.05 0 01-1.414-2.64 15.413 15.413 0 01-.875-2.872 15.62 15.62 0 01-.297-2.984v-51a15.65 15.65 0 01.297-2.985 15.375 15.375 0 014.192-7.828 15.235 15.235 0 014.96-3.316 15.286 15.286 0 012.868-.875c.984-.196 1.98-.293 2.984-.293h51c1.004 0 2 .097 2.985.293.984.199 1.941.488 2.867.875.93.383 1.812.855 2.644 1.414a15.265 15.265 0 015.637 6.863A15.363 15.363 0 01117 40.641v51a15.311 15.311 0 01-2.582 8.496 15.265 15.265 0 01-4.223 4.223 15.274 15.274 0 01-8.496 2.582zm-51-71.398a5.036 5.036 0 00-1.95.387 4.91 4.91 0 00-.883.472 4.976 4.976 0 00-1.406 1.407 4.91 4.91 0 00-.473.882 4.826 4.826 0 00-.289.957c-.066.329-.097.66-.097.993v51c0 .336.03.668.097.996.063.328.16.648.29.957a5.19 5.19 0 001.105 1.656 5.17 5.17 0 001.656 1.105 5.036 5.036 0 001.95.387h51c.335 0 .667-.031.995-.097.328-.067.649-.165.957-.29a5.48 5.48 0 00.883-.472 5.223 5.223 0 001.406-1.406 5.17 5.17 0 00.47-.883c.128-.309.226-.63.292-.957.063-.328.098-.66.098-.996v-51c0-.332-.035-.664-.098-.993a5.112 5.112 0 00-.293-.957 4.893 4.893 0 00-.469-.882 5.237 5.237 0 00-.636-.774 4.772 4.772 0 00-.77-.633 5.1 5.1 0 00-2.836-.86zm0 0"
            ></path>
          </g>
          <g clipPath="url(#bbf7a55c39)">
            <path
              fill="#fecd1a"
              d="M81.3 127.344h-51a15.62 15.62 0 01-2.984-.297c-.984-.2-1.941-.488-2.867-.875a14.947 14.947 0 01-2.644-1.414 15.265 15.265 0 01-5.637-6.863A15.274 15.274 0 0115 112.043v-51a15.311 15.311 0 012.582-8.496 15.265 15.265 0 014.223-4.223 15.274 15.274 0 018.496-2.582H40.5a5.19 5.19 0 011.953.387c.309.129.602.285.879.473a5.059 5.059 0 011.879 2.289c.129.308.227.629.293.957.062.328.098.66.098.996 0 .332-.036.664-.098.992a5.302 5.302 0 01-.762 1.84 5.059 5.059 0 01-1.41 1.406 5.011 5.011 0 01-1.836.762c-.328.066-.66.097-.996.097H30.3c-.335 0-.667.032-.995.098a5.408 5.408 0 00-.957.29 5.012 5.012 0 00-1.652 1.11c-.239.233-.45.49-.637.768a5.17 5.17 0 00-.47.883 5.112 5.112 0 00-.292.957c-.063.328-.098.66-.098.996v51c0 .332.035.664.098.992.066.328.164.649.293.957.125.313.285.606.469.883.187.277.398.535.636.773.235.239.492.45.77.633a5.1 5.1 0 002.836.86h51a5.036 5.036 0 001.949-.387 4.91 4.91 0 00.883-.473 4.976 4.976 0 001.406-1.406 4.91 4.91 0 00.473-.883c.129-.308.226-.629.289-.957.066-.328.097-.66.097-.992v-10.2c0-.335.036-.667.098-.995a5.302 5.302 0 01.762-1.84 5.059 5.059 0 011.41-1.406 5.011 5.011 0 011.836-.762c.328-.067.66-.098.996-.098a5.19 5.19 0 011.953.387c.309.129.602.285.879.473a5.059 5.059 0 011.879 2.289c.129.308.227.629.293.957.062.328.098.66.098.996v10.199a15.65 15.65 0 01-.297 2.984c-.2.985-.489 1.942-.875 2.868a15.225 15.225 0 01-3.317 4.96 15.235 15.235 0 01-4.96 3.317c-.926.387-1.883.676-2.868.875a15.62 15.62 0 01-2.984.297zm0 0"
            ></path>
          </g>
          <path
            fill={isVariant ? '#1a1a1a' : "#fff"}
            d="M33.875 1c-5.824 0-10.86-1.328-15.11-3.984C14.524-5.641 11.25-9.644 8.938-15c-2.304-5.363-3.453-11.988-3.453-19.875 0-7.957 1.383-14.613 4.157-19.969 2.77-5.363 6.609-9.36 11.515-11.984 4.914-2.625 10.617-3.938 17.11-3.938 6.675 0 12.367 1.246 17.078 3.735 4.707 2.48 8.015 6.168 9.922 11.062l-10.063 6.11-1.187-.047c-1.438-3.114-3.453-5.383-6.047-6.813-2.586-1.437-5.82-2.156-9.703-2.156-6.012 0-10.61 1.902-13.797 5.703-3.18 3.805-4.766 9.902-4.766 18.297 0 8.43 1.485 14.555 4.453 18.375 2.977 3.813 7.38 5.719 13.203 5.719 5.032 0 8.739-1.13 11.125-3.39 2.383-2.259 3.578-5.63 3.578-10.11v-3.016H35.547V-38.5H65.75V0H52.578v-9.36l-1-.14c-1.43 3.344-3.601 5.934-6.516 7.766C42.157.086 38.426 1 33.876 1zm0 0"
            transform="translate(161.907 114.307)"
          ></path>
          <path
            fill={isVariant ? '#1a1a1a' : "#fff"}
            d="M7.594 0v-73.766h13.203v28l1.015.203c1.75-2.582 3.895-4.57 6.438-5.968 2.54-1.395 5.61-2.094 9.203-2.094 11.613 0 17.422 6.586 17.422 19.75V0H41.656v-32.203c0-3.594-.718-6.203-2.156-7.828-1.43-1.625-3.652-2.438-6.672-2.438-3.687 0-6.617 1.235-8.781 3.703-2.168 2.47-3.25 6.309-3.25 11.516V0zm0 0"
            transform="translate(234.665 114.307)"
          ></path>
          <path
            fill={isVariant ? '#1a1a1a' : "#fff"}
            d="M30.578 1C25.43 1 20.91-.055 17.016-2.172c-3.899-2.113-6.922-5.21-9.078-9.297-2.149-4.094-3.22-9.035-3.22-14.828 0-5.781 1.071-10.723 3.22-14.828 2.156-4.102 5.18-7.21 9.078-9.328 3.894-2.113 8.414-3.172 13.562-3.172 5.156 0 9.68 1.059 13.578 3.172 3.895 2.117 6.926 5.226 9.094 9.328 2.164 4.105 3.25 9.047 3.25 14.828 0 5.793-1.086 10.735-3.25 14.828-2.168 4.086-5.2 7.184-9.094 9.297C40.258-.055 35.734 1 30.578 1zm0-11.203c8.176 0 12.266-5.363 12.266-16.094 0-5.562-1.055-9.64-3.157-12.234-2.093-2.594-5.132-3.89-9.109-3.89-8.168 0-12.25 5.374-12.25 16.124 0 10.73 4.082 16.094 12.25 16.094zm0 0"
            transform="translate(296.306 114.307)"
          ></path>
          <path
            fill={isVariant ? '#1a1a1a' : "#fff"}
            d="M24.625 1C21.031 1 17.738.578 14.75-.266 11.758-1.109 9.203-2.27 7.078-3.75c-2.117-1.477-3.68-3.172-4.687-5.078l7.343-7.922h1c1.75 2.355 3.817 4.121 6.204 5.297 2.382 1.18 4.976 1.765 7.78 1.765 2.571 0 4.509-.457 5.813-1.374 1.313-.926 1.969-2.25 1.969-3.97 0-1.331-.477-2.452-1.422-3.359-.937-.906-2.453-1.613-4.547-2.125l-6.968-1.672c-5.22-1.238-9.032-3.14-11.438-5.703-2.398-2.562-3.594-5.812-3.594-9.75 0-3.28.832-6.129 2.5-8.547 1.676-2.414 4.032-4.257 7.063-5.53 3.039-1.27 6.582-1.907 10.625-1.907 4.738 0 8.664.684 11.781 2.047 3.113 1.367 5.547 3.195 7.297 5.484l-7.063 7.64h-.953c-1.46-1.655-3.105-2.862-4.937-3.624-1.824-.77-3.867-1.156-6.125-1.156-5.219 0-7.828 1.625-7.828 4.875 0 1.367.484 2.48 1.453 3.343.968.856 2.676 1.555 5.125 2.094l6.5 1.438c5.343 1.136 9.242 2.968 11.703 5.5 2.469 2.53 3.703 5.93 3.703 10.187 0 3.344-.828 6.29-2.484 8.828-1.657 2.543-4.043 4.508-7.157 5.89C32.617.306 28.914 1 24.625 1zm0 0"
            transform="translate(357.47 114.307)"
          ></path>
          <path
            fill={isVariant ? '#1a1a1a' : "#fff"}
            d="M36.313-12.547l3.437 9.781C38.312-1.586 36.508-.664 34.344 0 32.187.664 29.738 1 27 1 21.312 1 16.988-.547 14.031-3.64c-2.96-3.102-4.437-7.614-4.437-13.532v-25H1.578v-10.5h8.016v-10.266l13.219-2.14v12.406h14.921v10.5H22.812v24.047c0 2.605.536 4.508 1.61 5.703 1.082 1.188 2.66 1.781 4.734 1.781 2.352 0 4.407-.632 6.157-1.906zm0 0"
            transform="translate(405.992 114.307)"
          ></path>
          <path
            fill="#fecd1a"
            d="M38.125 1c-6.43 0-12.09-1.332-16.984-4-4.899-2.676-8.73-6.691-11.5-12.047C6.867-20.41 5.484-27.02 5.484-34.875c0-7.863 1.383-14.473 4.157-19.828 2.77-5.363 6.601-9.379 11.5-12.047 4.894-2.676 10.554-4.016 16.984-4.016 6.707 0 12.406 1.313 17.094 3.938 4.695 2.625 8.047 6.625 10.047 12l-10.063 6.156-1.187-.047c-1.5-3.625-3.532-6.222-6.094-7.797-2.555-1.57-5.82-2.359-9.797-2.359-5.793 0-10.313 1.918-13.563 5.75-3.242 3.836-4.859 9.918-4.859 18.25 0 8.305 1.617 14.371 4.86 18.203 3.25 3.836 7.769 5.75 13.562 5.75 9.063 0 14.613-4.406 16.656-13.219h1.188l10.156 5.297c-1.906 6.617-5.168 11.578-9.781 14.89C51.738-.647 45.664 1 38.125 1zm0 0"
            transform="translate(469.92 114.307)"
          ></path>
          <path
            fill="#fecd1a"
            d="M21.86 1C17.116 1 13.5-.281 11-2.844 8.5-5.406 7.25-9.289 7.25-14.5v-59.266h13.219v58.735c0 3.219 1.336 4.828 4.015 4.828.758 0 1.567-.129 2.422-.39l.672.187L28.766-.234c-.73.375-1.7.671-2.907.89-1.21.227-2.543.344-4 .344zm0 0"
            transform="translate(538.336 114.307)"
          ></path>
          <path
            fill="#fecd1a"
            d="M30.578 1C25.43 1 20.91-.055 17.016-2.172c-3.899-2.113-6.922-5.21-9.078-9.297-2.149-4.094-3.22-9.035-3.22-14.828 0-5.781 1.071-10.723 3.22-14.828 2.156-4.102 5.18-7.21 9.078-9.328 3.894-2.113 8.414-3.172 13.562-3.172 5.156 0 9.68 1.059 13.578 3.172 3.895 2.117 6.926 5.226 9.094 9.328 2.164 4.105 3.25 9.047 3.25 14.828 0 5.793-1.086 10.735-3.25 14.828-2.168 4.086-5.2 7.184-9.094 9.297C40.258-.055 35.734 1 30.578 1zm0-11.203c8.176 0 12.266-5.363 12.266-16.094 0-5.562-1.055-9.64-3.157-12.234-2.093-2.594-5.132-3.89-9.109-3.89-8.168 0-12.25 5.374-12.25 16.124 0 10.73 4.082 16.094 12.25 16.094zm0 0"
            transform="translate(568.584 114.307)"
          ></path>
          <path
            fill="#fecd1a"
            d="M7.594 0v-52.625H20.28v7.719l1 .25c3.27-5.977 8.582-8.969 15.938-8.969 6.07 0 10.531 1.656 13.375 4.969 2.851 3.304 4.281 8.117 4.281 14.437V0H41.656v-32.594c0-3.5-.703-6.02-2.11-7.562-1.398-1.54-3.636-2.313-6.718-2.313-3.781 0-6.734 1.227-8.86 3.672-2.116 2.45-3.171 6.297-3.171 11.547V0zm0 0"
            transform="translate(629.748 114.307)"
          ></path>
          <path
            fill="#fecd1a"
            d="M52.016-23.328H18.172c.383 4.574 1.691 7.906 3.922 10 2.226 2.086 5.265 3.125 9.11 3.125 3.019 0 5.468-.563 7.343-1.688 1.883-1.132 3.398-2.847 4.547-5.14h1l8.781 5.625c-1.719 3.687-4.313 6.68-7.781 8.969C41.625-.146 36.992 1 31.204 1 25.765 1 21.065-.031 17.108-2.094c-3.96-2.07-7.015-5.148-9.172-9.234C5.79-15.422 4.72-20.41 4.72-26.297c0-5.976 1.129-11.02 3.39-15.125 2.258-4.101 5.313-7.16 9.157-9.172 3.851-2.02 8.18-3.031 12.984-3.031 7.445 0 13.148 1.977 17.11 5.922 3.956 3.937 5.937 9.726 5.937 17.36 0 2.773-.094 4.917-.281 6.437zm-11.36-8.547c-.125-4.008-1.058-6.926-2.797-8.75-1.73-1.832-4.265-2.75-7.609-2.75-6.68 0-10.605 3.836-11.781 11.5zm0 0"
            transform="translate(691.39 114.307)"
          ></path>
        </svg>
      </Link>
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      version="1"
      viewBox="0 0 750 150"
    >
      <defs>
        <clipPath id="c5cf099349">
          <path d="M35 25.344h82V107H35zm0 0"></path>
        </clipPath>
        <clipPath id="bbf7a55c39">
          <path d="M15 45h82v82.344H15zm0 0"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#c5cf099349)">
        <path
          fill="#fecd1a"
          d="M101.7 106.941h-51a15.274 15.274 0 01-8.496-2.582 15.235 15.235 0 01-4.22-4.222 15.05 15.05 0 01-1.414-2.64 15.413 15.413 0 01-.875-2.872 15.62 15.62 0 01-.297-2.984v-51a15.65 15.65 0 01.297-2.985 15.375 15.375 0 014.192-7.828 15.235 15.235 0 014.96-3.316 15.286 15.286 0 012.868-.875c.984-.196 1.98-.293 2.984-.293h51c1.004 0 2 .097 2.985.293.984.199 1.941.488 2.867.875.93.383 1.812.855 2.644 1.414a15.265 15.265 0 015.637 6.863A15.363 15.363 0 01117 40.641v51a15.311 15.311 0 01-2.582 8.496 15.265 15.265 0 01-4.223 4.223 15.274 15.274 0 01-8.496 2.582zm-51-71.398a5.036 5.036 0 00-1.95.387 4.91 4.91 0 00-.883.472 4.976 4.976 0 00-1.406 1.407 4.91 4.91 0 00-.473.882 4.826 4.826 0 00-.289.957c-.066.329-.097.66-.097.993v51c0 .336.03.668.097.996.063.328.16.648.29.957a5.19 5.19 0 001.105 1.656 5.17 5.17 0 001.656 1.105 5.036 5.036 0 001.95.387h51c.335 0 .667-.031.995-.097.328-.067.649-.165.957-.29a5.48 5.48 0 00.883-.472 5.223 5.223 0 001.406-1.406 5.17 5.17 0 00.47-.883c.128-.309.226-.63.292-.957.063-.328.098-.66.098-.996v-51c0-.332-.035-.664-.098-.993a5.112 5.112 0 00-.293-.957 4.893 4.893 0 00-.469-.882 5.237 5.237 0 00-.636-.774 4.772 4.772 0 00-.77-.633 5.1 5.1 0 00-2.836-.86zm0 0"
        ></path>
      </g>
      <g clipPath="url(#bbf7a55c39)">
        <path
          fill="#fecd1a"
          d="M81.3 127.344h-51a15.62 15.62 0 01-2.984-.297c-.984-.2-1.941-.488-2.867-.875a14.947 14.947 0 01-2.644-1.414 15.265 15.265 0 01-5.637-6.863A15.274 15.274 0 0115 112.043v-51a15.311 15.311 0 012.582-8.496 15.265 15.265 0 014.223-4.223 15.274 15.274 0 018.496-2.582H40.5a5.19 5.19 0 011.953.387c.309.129.602.285.879.473a5.059 5.059 0 011.879 2.289c.129.308.227.629.293.957.062.328.098.66.098.996 0 .332-.036.664-.098.992a5.302 5.302 0 01-.762 1.84 5.059 5.059 0 01-1.41 1.406 5.011 5.011 0 01-1.836.762c-.328.066-.66.097-.996.097H30.3c-.335 0-.667.032-.995.098a5.408 5.408 0 00-.957.29 5.012 5.012 0 00-1.652 1.11c-.239.233-.45.49-.637.768a5.17 5.17 0 00-.47.883 5.112 5.112 0 00-.292.957c-.063.328-.098.66-.098.996v51c0 .332.035.664.098.992.066.328.164.649.293.957.125.313.285.606.469.883.187.277.398.535.636.773.235.239.492.45.77.633a5.1 5.1 0 002.836.86h51a5.036 5.036 0 001.949-.387 4.91 4.91 0 00.883-.473 4.976 4.976 0 001.406-1.406 4.91 4.91 0 00.473-.883c.129-.308.226-.629.289-.957.066-.328.097-.66.097-.992v-10.2c0-.335.036-.667.098-.995a5.302 5.302 0 01.762-1.84 5.059 5.059 0 011.41-1.406 5.011 5.011 0 011.836-.762c.328-.067.66-.098.996-.098a5.19 5.19 0 011.953.387c.309.129.602.285.879.473a5.059 5.059 0 011.879 2.289c.129.308.227.629.293.957.062.328.098.66.098.996v10.199a15.65 15.65 0 01-.297 2.984c-.2.985-.489 1.942-.875 2.868a15.225 15.225 0 01-3.317 4.96 15.235 15.235 0 01-4.96 3.317c-.926.387-1.883.676-2.868.875a15.62 15.62 0 01-2.984.297zm0 0"
        ></path>
      </g>
      <path
        fill="#fff"
        d="M33.875 1c-5.824 0-10.86-1.328-15.11-3.984C14.524-5.641 11.25-9.644 8.938-15c-2.304-5.363-3.453-11.988-3.453-19.875 0-7.957 1.383-14.613 4.157-19.969 2.77-5.363 6.609-9.36 11.515-11.984 4.914-2.625 10.617-3.938 17.11-3.938 6.675 0 12.367 1.246 17.078 3.735 4.707 2.48 8.015 6.168 9.922 11.062l-10.063 6.11-1.187-.047c-1.438-3.114-3.453-5.383-6.047-6.813-2.586-1.437-5.82-2.156-9.703-2.156-6.012 0-10.61 1.902-13.797 5.703-3.18 3.805-4.766 9.902-4.766 18.297 0 8.43 1.485 14.555 4.453 18.375 2.977 3.813 7.38 5.719 13.203 5.719 5.032 0 8.739-1.13 11.125-3.39 2.383-2.259 3.578-5.63 3.578-10.11v-3.016H35.547V-38.5H65.75V0H52.578v-9.36l-1-.14c-1.43 3.344-3.601 5.934-6.516 7.766C42.157.086 38.426 1 33.876 1zm0 0"
        transform="translate(161.907 114.307)"
      ></path>
      <path
        fill="#fff"
        d="M7.594 0v-73.766h13.203v28l1.015.203c1.75-2.582 3.895-4.57 6.438-5.968 2.54-1.395 5.61-2.094 9.203-2.094 11.613 0 17.422 6.586 17.422 19.75V0H41.656v-32.203c0-3.594-.718-6.203-2.156-7.828-1.43-1.625-3.652-2.438-6.672-2.438-3.687 0-6.617 1.235-8.781 3.703-2.168 2.47-3.25 6.309-3.25 11.516V0zm0 0"
        transform="translate(234.665 114.307)"
      ></path>
      <path
        fill="#fff"
        d="M30.578 1C25.43 1 20.91-.055 17.016-2.172c-3.899-2.113-6.922-5.21-9.078-9.297-2.149-4.094-3.22-9.035-3.22-14.828 0-5.781 1.071-10.723 3.22-14.828 2.156-4.102 5.18-7.21 9.078-9.328 3.894-2.113 8.414-3.172 13.562-3.172 5.156 0 9.68 1.059 13.578 3.172 3.895 2.117 6.926 5.226 9.094 9.328 2.164 4.105 3.25 9.047 3.25 14.828 0 5.793-1.086 10.735-3.25 14.828-2.168 4.086-5.2 7.184-9.094 9.297C40.258-.055 35.734 1 30.578 1zm0-11.203c8.176 0 12.266-5.363 12.266-16.094 0-5.562-1.055-9.64-3.157-12.234-2.093-2.594-5.132-3.89-9.109-3.89-8.168 0-12.25 5.374-12.25 16.124 0 10.73 4.082 16.094 12.25 16.094zm0 0"
        transform="translate(296.306 114.307)"
      ></path>
      <path
        fill="#fff"
        d="M24.625 1C21.031 1 17.738.578 14.75-.266 11.758-1.109 9.203-2.27 7.078-3.75c-2.117-1.477-3.68-3.172-4.687-5.078l7.343-7.922h1c1.75 2.355 3.817 4.121 6.204 5.297 2.382 1.18 4.976 1.765 7.78 1.765 2.571 0 4.509-.457 5.813-1.374 1.313-.926 1.969-2.25 1.969-3.97 0-1.331-.477-2.452-1.422-3.359-.937-.906-2.453-1.613-4.547-2.125l-6.968-1.672c-5.22-1.238-9.032-3.14-11.438-5.703-2.398-2.562-3.594-5.812-3.594-9.75 0-3.28.832-6.129 2.5-8.547 1.676-2.414 4.032-4.257 7.063-5.53 3.039-1.27 6.582-1.907 10.625-1.907 4.738 0 8.664.684 11.781 2.047 3.113 1.367 5.547 3.195 7.297 5.484l-7.063 7.64h-.953c-1.46-1.655-3.105-2.862-4.937-3.624-1.824-.77-3.867-1.156-6.125-1.156-5.219 0-7.828 1.625-7.828 4.875 0 1.367.484 2.48 1.453 3.343.968.856 2.676 1.555 5.125 2.094l6.5 1.438c5.343 1.136 9.242 2.968 11.703 5.5 2.469 2.53 3.703 5.93 3.703 10.187 0 3.344-.828 6.29-2.484 8.828-1.657 2.543-4.043 4.508-7.157 5.89C32.617.306 28.914 1 24.625 1zm0 0"
        transform="translate(357.47 114.307)"
      ></path>
      <path
        fill="#fff"
        d="M36.313-12.547l3.437 9.781C38.312-1.586 36.508-.664 34.344 0 32.187.664 29.738 1 27 1 21.312 1 16.988-.547 14.031-3.64c-2.96-3.102-4.437-7.614-4.437-13.532v-25H1.578v-10.5h8.016v-10.266l13.219-2.14v12.406h14.921v10.5H22.812v24.047c0 2.605.536 4.508 1.61 5.703 1.082 1.188 2.66 1.781 4.734 1.781 2.352 0 4.407-.632 6.157-1.906zm0 0"
        transform="translate(405.992 114.307)"
      ></path>
      <path
        fill="#fecd1a"
        d="M38.125 1c-6.43 0-12.09-1.332-16.984-4-4.899-2.676-8.73-6.691-11.5-12.047C6.867-20.41 5.484-27.02 5.484-34.875c0-7.863 1.383-14.473 4.157-19.828 2.77-5.363 6.601-9.379 11.5-12.047 4.894-2.676 10.554-4.016 16.984-4.016 6.707 0 12.406 1.313 17.094 3.938 4.695 2.625 8.047 6.625 10.047 12l-10.063 6.156-1.187-.047c-1.5-3.625-3.532-6.222-6.094-7.797-2.555-1.57-5.82-2.359-9.797-2.359-5.793 0-10.313 1.918-13.563 5.75-3.242 3.836-4.859 9.918-4.859 18.25 0 8.305 1.617 14.371 4.86 18.203 3.25 3.836 7.769 5.75 13.562 5.75 9.063 0 14.613-4.406 16.656-13.219h1.188l10.156 5.297c-1.906 6.617-5.168 11.578-9.781 14.89C51.738-.647 45.664 1 38.125 1zm0 0"
        transform="translate(469.92 114.307)"
      ></path>
      <path
        fill="#fecd1a"
        d="M21.86 1C17.116 1 13.5-.281 11-2.844 8.5-5.406 7.25-9.289 7.25-14.5v-59.266h13.219v58.735c0 3.219 1.336 4.828 4.015 4.828.758 0 1.567-.129 2.422-.39l.672.187L28.766-.234c-.73.375-1.7.671-2.907.89-1.21.227-2.543.344-4 .344zm0 0"
        transform="translate(538.336 114.307)"
      ></path>
      <path
        fill="#fecd1a"
        d="M30.578 1C25.43 1 20.91-.055 17.016-2.172c-3.899-2.113-6.922-5.21-9.078-9.297-2.149-4.094-3.22-9.035-3.22-14.828 0-5.781 1.071-10.723 3.22-14.828 2.156-4.102 5.18-7.21 9.078-9.328 3.894-2.113 8.414-3.172 13.562-3.172 5.156 0 9.68 1.059 13.578 3.172 3.895 2.117 6.926 5.226 9.094 9.328 2.164 4.105 3.25 9.047 3.25 14.828 0 5.793-1.086 10.735-3.25 14.828-2.168 4.086-5.2 7.184-9.094 9.297C40.258-.055 35.734 1 30.578 1zm0-11.203c8.176 0 12.266-5.363 12.266-16.094 0-5.562-1.055-9.64-3.157-12.234-2.093-2.594-5.132-3.89-9.109-3.89-8.168 0-12.25 5.374-12.25 16.124 0 10.73 4.082 16.094 12.25 16.094zm0 0"
        transform="translate(568.584 114.307)"
      ></path>
      <path
        fill="#fecd1a"
        d="M7.594 0v-52.625H20.28v7.719l1 .25c3.27-5.977 8.582-8.969 15.938-8.969 6.07 0 10.531 1.656 13.375 4.969 2.851 3.304 4.281 8.117 4.281 14.437V0H41.656v-32.594c0-3.5-.703-6.02-2.11-7.562-1.398-1.54-3.636-2.313-6.718-2.313-3.781 0-6.734 1.227-8.86 3.672-2.116 2.45-3.171 6.297-3.171 11.547V0zm0 0"
        transform="translate(629.748 114.307)"
      ></path>
      <path
        fill="#fecd1a"
        d="M52.016-23.328H18.172c.383 4.574 1.691 7.906 3.922 10 2.226 2.086 5.265 3.125 9.11 3.125 3.019 0 5.468-.563 7.343-1.688 1.883-1.132 3.398-2.847 4.547-5.14h1l8.781 5.625c-1.719 3.687-4.313 6.68-7.781 8.969C41.625-.146 36.992 1 31.204 1 25.765 1 21.065-.031 17.108-2.094c-3.96-2.07-7.015-5.148-9.172-9.234C5.79-15.422 4.72-20.41 4.72-26.297c0-5.976 1.129-11.02 3.39-15.125 2.258-4.101 5.313-7.16 9.157-9.172 3.851-2.02 8.18-3.031 12.984-3.031 7.445 0 13.148 1.977 17.11 5.922 3.956 3.937 5.937 9.726 5.937 17.36 0 2.773-.094 4.917-.281 6.437zm-11.36-8.547c-.125-4.008-1.058-6.926-2.797-8.75-1.73-1.832-4.265-2.75-7.609-2.75-6.68 0-10.605 3.836-11.781 11.5zm0 0"
        transform="translate(691.39 114.307)"
      ></path>
    </svg>
  )
};

export default Logo;
