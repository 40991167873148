import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Landing/Footer/Footer";
import Header from "../Landing/Header/Header";
import MetaTag from "../../Components/MetaTag";
import config from "../../lib/config/index"
import Blogs from "packages/blog/Blogs";

export default function BlogPage() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <MetaTag title={`${config.app_name} • Blog`}/>

      <Header hideContent/>

      <Blogs grid={3} />

      {/* <PrivacySVG /> */}
      <Footer selected="Privacy" />
    </div>
  );
}
