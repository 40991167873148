import { types } from "../actions/accessTokenActions";

export default function persistedStorageReducer(state = [], {type, payload}){
    switch (type){
        case types.ADD_TOKEN: {
            return payload.token          
        }

        case types.REMOVE_TOKEN: {
            return null
        }

        default:
            return state
    }
}