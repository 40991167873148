import React, { useEffect, useState } from 'react';
import BlogGrid from './BlogGrid';
import blogsData from './data/blogs.json';
import { Box, Text } from '@chakra-ui/react';
import config from "lib/config/index"


const HeaderSubtitle = () => {
  return (
    <Box textAlign="left" mb={4}>
      <Text fontSize="4xl" fontWeight="bold">
        {config.app_name} Blog
      </Text>
      <Text fontSize="lg" color="gray.600">
        All the things you need
      </Text>
    </Box>
  );
};


const Blogs = () => {
  const [filteredBlogsData, setFilteredBlogsData] = useState([]);

  useEffect(() => {
    setFilteredBlogsData(blogsData);
  }, []);

  return (
    <Box maxW="1200px" mx="auto">
      <HeaderSubtitle />

      <BlogGrid data={filteredBlogsData} grid={2}/>
    </Box>
  );
};

export default Blogs;
