import React, { useContext } from "react";
import Plans from "./Plans";
import { Box, Image, Tag, Text } from "@chakra-ui/react";
import { ThemeContext } from "Context/ThemeContext";

export default function Pricing() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box position='relative' data-aos="fade-down">
      <Box position='static' textAlign='center' zIndex={10}>
        <Text maxWidth='2xl' m='auto' zIndex={10} px='4' fontSize={{ base: '2xl', md: '4xl' }} fontWeight={500} color='gray.100'>The easiest way to drive more sales <Text as='span' zIndex={10} fontSize={{ base: '2xl', md: '4xl' }} fontWeight={500} color={theme.primary}>for your business</Text></Text>
    
      </Box>


      <Box position='absolute' top='-5%' left='10%' opacity={0.05} pointerEvents='none' height='819px' width='819px' overflow='hidden' zIndex={-1}>
        <Image src={require('../../Images/Landing/Blur/orange.png')} />
      </Box>

      <Box position='absolute' top='1%' right='1%' opacity={0.05} pointerEvents='none' height='819px' width='819px' overflow='hidden' zIndex={-1}>
        <Image src={require('../../Images/Landing/Blur/orange.png')} />
      </Box>

      <Box zIndex={10}>
        <Plans />
      </Box>
    </Box>
  )
}
