import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  useDisclosure, Text, Badge, Image, TableContainer
} from '@chakra-ui/react';
import { getOrdersAPI } from "lib/API/orderAPI";
import ViewOrder from './ViewOrder';
import { toast } from 'react-toastify';
import config from "lib/config/index"

const getStatusColor = (status) => {
  switch (status) {
    case 'In Progress':
      return 'orange';
    case 'Finished':
      return 'green';
    case 'Failed':
      return 'red';
    default:
      return 'gray';
  }
}

const OrdersTable = ({ orders }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState({ id: '', quantity: 0, name: '', extension: '', progress: -1 });

  const onClickHandler = (order) => {
    setSelectedOrder(order)

    if (order.status === 'In Progress') {
      toast('⚠️ Processing Images', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    } else {
      onOpen()
    }
  }

  return (
    <>
      <TableContainer borderWidth={1} rounded='md' bg='#262626'>
        <Table variant="simple" size="md">
          <Thead bg='#f6f8fa'>
            <Tr>
              <Th fontWeight="bold" py={4}>#</Th>
              <Th fontWeight="bold" py={4}>Image</Th>
              <Th fontWeight="bold" py={4}>Name</Th>
              <Th fontWeight="bold" py={4}>Quantity</Th>
              <Th fontWeight="bold" py={4}>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orders.map((order, index) => (
              <Tr key={order._id} cursor="pointer" _hover={{ background: "#333333" }} onClick={() => onClickHandler(order)} color='#fff'>
                <Td py={3} fontSize='sm'>{index+1}</Td>
                <Td py={3}>
                  {/* Placeholder for an image thumbnail */}
                  <Image borderWidth={1} borderColor='#fff' src={ `${config.order_image_domain}/${order._id}/${order.progress > -1 ? "thumbnail" : "original"}`} w="40px" h="40px" objectFit="cover" borderRadius="md" />
                </Td>
                <Td py={3} fontSize='sm'>{order.name}</Td>
                <Td py={3} fontSize='sm'>{order.quantity}</Td>
                <Td py={3} fontSize='sm'>
                  <Badge colorScheme={getStatusColor(order.status)} px={3} size='sm'>
                    {order.status}
                  </Badge>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter='blur(10px) hue-rotate(90deg)' />
        <ModalContent bg='#1a1a1a' maxWidth={750}>
          <ModalHeader color='#fff'>Order: {selectedOrder._id}</ModalHeader>
          <ModalCloseButton color='#fff' />
          <ModalBody>
            <ViewOrder onClose={onClose} id={selectedOrder._id} quantity={selectedOrder.quantity} name={selectedOrder.name} extension={selectedOrder.extension} progress={selectedOrder.progress} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OrdersTable;
