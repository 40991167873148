import React from 'react'
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from "react-icons/fa";
import './inlineError.scss'

export default function InlineError({ text, width, textStyle, inlineStyle }) {
    return text ? (
        <div className="inlineError" style={{...inlineStyle}}>
            <div className="icon">
                <FaExclamationTriangle width={width} color="#fc6464" />
            </div>
            <div className="text">
                <span style={{color: '#fc6464', marginLeft: 2, ...textStyle}}>{text}</span>
            </div>
        </div>
    ) : <div></div>
}

InlineError.propTypes = {
    text: PropTypes.string.isRequired
}
