import { types } from "../../actions/userActions";
import intialCallsReducer from "./initialCallsReducer"

export default function userReducer(state = [], {type, payload}){
    if (type.split(":")[0] === "user_initialCalls") {
        return intialCallsReducer(state, {type, payload})

    }  else {
        switch (type){
            case types.UPDATE_USER_INFO: {
                const { uid, fullName, email, profileImageUrl, emailVerified, currentPlanId } = payload
                return {
                    ...state,
                    info: {
                        id: ("uid" in payload) ? uid : state.info.uid,
                        fullName: ("fullName" in payload) ? fullName : state.info.fullName,
                        email: ("email" in payload) ? email : state.info.email,
                        profileImageUrl: ("profileImageUrl" in payload) ? profileImageUrl : state.info.profileImageUrl,
                        emailVerified: ("emailVerified" in payload) ? emailVerified : state.info.emailVerified,
                    }
                }
            }

            case types.UPDATE_EMAIL_PREFERENCES: {
                return {
                    ...state, 
                    emailPreference: {
                        feedback: payload.feedback,
                        reminder: payload.reminder,
                        product: payload.product,
                        news: payload.news
                    }
                }

            }

            case types.UPDATE_EMAIL_PREFERENCE: {
                const { type, val } = payload
                return {
                    ...state,
                    emailPreference: {
                        ...state.emailPreference,
                        [type]: val
                    }
                }
            }

            default:
                return state
        }    }
    
}