import { getAPIWrapper, postAPIWrapper } from "./API";

export const getPlansAPI = () => {
    return getAPIWrapper("/api/billing/plans");
};

export const createCheckoutSessionAPI = priceId => {
    return postAPIWrapper("/api/billing/create-checkout-session", { priceId });
};

export const createPortalSessionAPI = () => {
    return postAPIWrapper("/api/billing/create-portal-session");
};

export default {
    getPlansAPI,
    createCheckoutSessionAPI,
    createPortalSessionAPI
}