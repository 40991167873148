import { Box, Button, Flex, Image } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react'
import Footer from './Landing/Footer/Footer';
import Nav from './Landing/Nav/Nav';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'Context/ThemeContext';

export default function ErrorPage() {
  const { theme } = useContext(ThemeContext);
  const accessToken = useSelector((state) => state.accessToken);

  useEffect(() => {
    heap.track('404 Error Page', {url: window.location.pathname });
  })

  return (
    <Flex position='relative' overflowY='scroll' overflowX='hidden' height='100vh' justify='space-between' direction='column'>
      <Nav />

      <Flex justify='center' align='center' direction='column' mb="24">
        <Image src={require('../Images/Landing/404.png')} maxWidth={{ base: 300, md: 500 }} />
        { accessToken ?
          <Button size='md' href='/dashboard' as={'a'} colorScheme='blue' display='inline-flex' fontSize={'sm'} fontWeight={500} color={'white'} bg={theme.primary} _hover={{ bg: theme.hover }}>
            Go back to your dashboard
          </Button>
          :
          <Button size='md' bg={theme.primary} _hover={{ bg: theme.hover }} color='white' fontSize={'sm'} width='fit-content' as={Link} to="/">Go back to the landing page</Button>
        }
      </Flex>

      <Box position='absolute' top='10%' left='15%' opacity={0.7} pointerEvents='none' height='819px' width='819px' overflow='hidden' zIndex={-1}>
        <Image src={require('../Images/Landing/Blur/Lightblue.png')} />
      </Box>

      <Box position='absolute' top='1%' right='1%' opacity={0.7} pointerEvents='none' height='819px' width='819px' overflow='hidden' zIndex={-1}>
        <Image src={require('../Images/Landing/Blur/Lightblue.png')} />
      </Box>
      <Footer />
    </Flex>
  );
}
