import React, { useContext } from "react";
import Footer from "./Footer/Footer";
import Nav from "./Nav/Nav";
import HeroHeader from "./Header/HeroHeader";
import LandingCTA from "./CTA/LandingCTA";
// import ShowCard from "./ShowCard/ShowCard";
import MetaTag from "Components/MetaTag";
import { Box, Divider, Progress, Text } from "@chakra-ui/react";
import ShowCard from "./ShowCard/ShowCard";
import Pricing from "Components/Billing/Pricing";
import GhostClone from "Images/Landing/GhostClone.mp4"
import { ThemeContext } from "Context/ThemeContext";
import GhostMatch from "./GhostMatch/GhostMatch";
import GhostMatchBanner from "Pages/RestrictedPages/GhostMatchBanner";
// import ShowTabs from "./TabsCard/ShowTabs";

export default function LandingV2() {
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ fontFamily: 'Inter', background: '#1a1a1a', overflow: 'hidden' }}>
      <MetaTag title='Ghost Clone | One Image Unlimited Uses' />
      {/* <Box>
        <GhostMatchBanner />
      </Box> */}
      
      <Nav />
      <HeroHeader />

      <Divider borderColor='rgba(45, 103, 246, 0.2)' />
        
      <ShowCard />

      <Box maxWidth='6xl' m='auto' textAlign='center' px='4' pt='8' pb='16'>
        <Text py='3' px='6' bg='#262626' color={theme.primary} fontWeight={700} width='fit-content' m='auto' rounded='full' mb='10'>See How It Works!</Text>

        <Box borderRadius='8px' boxShadow='0 0 50px 10px rgba(62,57,95,.1)'>
          <video autoPlay playsInline loop muted style={{ borderRadius: 8}} width="100%" height="100%" poster={require('Images/Landing/GhostClone-thumbnail.jpg')}>
            <source src={GhostClone} type="video/mp4" />
          </video>
        </Box>
      </Box>

      {/* <Box maxWidth='6xl' m='auto' textAlign='center' px='4' pt='8' pb='16'>
        <GhostMatch />
      </Box> */}
      
      <section id="pricingSection">
        <Pricing />
      </section>

      <Footer />
    </div>
  );
}