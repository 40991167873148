import React, { useContext } from 'react';
import { Box, Flex, Text, Divider, Stack, Icon, Progress } from '@chakra-ui/react';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'Context/ThemeContext';

const CreditsBar = () => {
    const { theme } = useContext(ThemeContext);
    const billing = useSelector(state => state.billing);
    const { creditsUsed, totalCredits } = billing;
    const percentageUsed = (creditsUsed / totalCredits) * 100;

    return (
        <Flex
            direction="column"
            p={4} // Reduced padding
            bg='#262626'
            borderRadius="md"
            boxShadow="md"
            maxWidth="100%"
            borderWidth={1}
            borderColor={theme.primary}
        >
            <Stack 
                direction="row"
                alignItems="center"
                spacing={3} // Reduced spacing
                width="100%"
                justifyContent="space-between"
            >
                <Flex alignItems="center">
                    <Icon as={AiOutlineCreditCard} w={5} h={5} mr={2} color="white" />
                    <Text fontSize="sm" fontWeight="medium" color="white">Credits</Text>
                </Flex>
                <Divider orientation="vertical" borderColor="gray.500" />
                <Text align="center" fontSize="sm" color="white">
                    {creditsUsed} / {totalCredits}
                </Text>
            </Stack>
            <Box my='2' width="100%"> 
                <Progress 
                    borderRadius="md"
                    value={percentageUsed} 
                    colorScheme='yellow'
                    size="xs" // Reduced size
                />
            </Box>
        </Flex>
    );
};

export default CreditsBar;
