export const updateCollapseSidebarAction = (collapseSidebar) => {
  return {
    type: types.SET_COLLAPSE_SIDEBAR,
    payload: { collapseSidebar }
  }
}

export const types = {
  SET_COLLAPSE_SIDEBAR: 'collapseSidebar:setCollapseSidebar',
}

