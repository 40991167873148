import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import reduxStore from "./lib/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "./history";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ScrollToTop from "./scrollToTop";
import { theme } from './chakra';
import { setInternetChecker, setAppVersion } from "lib/API/API"
import { ThemeProvider } from "Context/ThemeContext";

function checkInternetConnection() {
  return window.navigator.onLine;
}

setInternetChecker(checkInternetConnection)
setAppVersion("1.0.0")

const { store, persistor } = reduxStore

const container = document.getElementById("root");
const root = createRoot(container);

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history.replace(path);
}

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop />
        <ChakraProvider theme={theme}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ChakraProvider>
      </BrowserRouter>
    </PersistGate>
    <ToastContainer
      position="bottom-left"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
