// ThemeContext.js
import { darken, getContrast, lighten, mix, rgba } from 'polished';
import React, { createContext } from 'react';

const ThemeContext = createContext();

const getVariants = (color) => {
const alphaValue = 0.35;

  return {
    primary: color,
    hover: darken(0.1, color),
    accent: mix(0.9, "white", color),
    color: getContrast(color, "black") > 4.5 ? "black" : "white",
    lightGradient: lighten(0.05, color),
    darkGradient: lighten(0.1, color),
    shadow: rgba(color, alphaValue)
  }
}

const ThemeProvider = ({ children }) => {

  const theme = {
    ...getVariants("#FECD1A"),
    sidebar: '#1a1a1a',
    // logo: `${config.branding_image_domain}/${"hubfit.png"}`,
    name: 'GhostClone'
  }

  return (
    <ThemeContext.Provider value={{ theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };