export const notificationInitialStore = {
    initialCall: { requested: false, loading: false, error: null },
    preference: {
        forms: {email: true, notification: true},
        progressPhotos: {email: true, notification: true},
        workoutCompletion: {email: true, notification: true},
        metrics: {email: true, notification: true},
        messages: {email: true, notification: true},
    },
    notifications: []
}