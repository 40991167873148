import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react'; // Import the Heading component from Chakra UI
import MetaTag from 'Components/MetaTag';

import BlogHeader from './BlogHeader';
import BlogSection from './BlogSection';
import BlogGrid from './BlogGrid';
import BlogCTA from './BlogCTA';

const Blog = () => {
  const { blog_id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [filteredBlogsData, setFilteredBlogsData] = useState(null);

  useEffect(() => {
    // Dynamically import the appropriate JSON file based on the blog_id parameter
    import(`./data/${blog_id}/data.json`)
      .then((module) => {
        setBlogData(module.default);
      })
      .catch((error) => {
        console.error(error);
        setBlogData(null); // Set blogData to null if the file doesn't exist or there's an error
      });
  }, [blog_id]);

  useEffect(() => {
    // Check if blogData is available and if it contains relatedBlogs
    if (blogData && blogData.relatedBlogs) {
      // Assuming the relatedBlogs contains blog IDs, e.g., ["blog-1", "blog-2", ...]
      // You can modify this based on how your relatedBlogs data is structured.
      const filteredBlogs = blogData.relatedBlogs.map((relatedBlogID) => {
        try {
          const relatedBlogData = import(`./data/${relatedBlogID}/data.json`);
          return relatedBlogData;
        } catch (error) {
          console.error(`Error loading related blog with ID: ${relatedBlogID}`, error);
          return null;
        }
      });

      // Wait for all the promises to resolve and remove any blogs that couldn't be loaded successfully
      Promise.all(filteredBlogs)
        .then((validFilteredBlogs) => validFilteredBlogs.filter((blog) => blog !== null))
        .then((filteredData) => setFilteredBlogsData(filteredData));
    }
  }, [blogData]);

  // Check if the blogData is loaded, if not, display a loading state or an error message
  if (!blogData) {
    return <div>Loading...</div>; // You can show a loader here or customize the loading state
  }

  const { title, date, metaDescription, sections, id } = blogData;

  return (
    <Box maxW="1200px" mx="auto">
      <MetaTag
        title={title}
        desc={metaDescription}
        ogImage={require(`./data/${id}/featureImage.jpg`)}
      />

      <BlogHeader imageUrl={require(`./data/${id}/featureImage.jpg`)} title={title} date={date} />

      {sections.map((section, index) => (
        <BlogSection key={index} heading={section.heading} image={section.image} text={section.text} id={id} />
      ))}

      {/* Styled "Related Posts" title */}
      <Heading as="h2" size="lg" textAlign="left" mb="2" mt="50">Related Posts</Heading>

      {filteredBlogsData && <BlogGrid data={filteredBlogsData} grid={3} />}

      <BlogCTA />
    </Box>
  );
};

export default Blog;