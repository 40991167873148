import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Landing/Footer/Footer";
import Header from "../Landing/Header/Header";
import Blog from "packages/blog/Blog";

export default function BlogPage() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>      
      <Header hideContent/>

      <Blog grid={2} />

      <Footer selected="Privacy" />
    </div>
  );
}
