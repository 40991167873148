import React, { useContext } from 'react';
import { Box, Flex, Heading, Icon, Image, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import Card from './Card';
import Theme from 'Theme';
import { BsCardChecklist } from 'react-icons/bs';
import { IoNutritionOutline, IoBodyOutline } from 'react-icons/io5';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdAutoGraph } from 'react-icons/md';
import { ThemeContext } from 'Context/ThemeContext';

export default function ShowCard() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box position='relative' py={16} px='32px' overflow='hidden'>
      <Box maxWidth='7xl' m='auto' textAlign='center'>
        <Text fontSize='sm' textTransform='uppercase' fontWeight={600} letterSpacing={1} color={theme.primary}>Clone Your Images</Text>
        {/* <Text py='3' px='6' bg='#f3f5ff' color='#2051f1' fontWeight={700} width='fit-content' m='auto' rounded='full'>All-In-One Solution</Text>
        <Text maxW='lg' m='auto' fontWeight={700} fontSize={{ base: '3xl', md: '4xl' }} my='4'>One-stop platform, packed with <Text as='span' backgroundClip='text' backgroundImage='linear-gradient(90deg,#4457ff,#6877ff)' textShadow='0 0 20px rgba(68,87,255,.35)'>powerful features.</Text></Text> */}
                
        <Stack spacing={16} marginTop={12}>
          {CARDS.map(({ title, icon, text, src, position, points }, index) => (
            <Card key={index} index={index} title={title} icon={icon} text={text} src={src} position={position} points={points} />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}


const CARDS = [
  {
    title: 'AI-Powered Cloning',
    text: '',
    src: "1.png",
    imageAlt: "1.png",
    position: "left",
    points: [
      "Create quick clones in just minutes",
      "Clones retain original quality",
      "Bulk cloning, saving you time and effort",
    ],
    icon: BsCardChecklist,
  },
  {
    title: 'Storage',
    text: '',
    // src: "training.png",
    src: "2.png",
    imageAlt: "2.png",
    position: "right",
    points: [
      "Unlimited storage",
      "Organised neatly and accessible anytime",
      "Unlimited downloads for your clones",
    ],
    icon: IoBodyOutline,
  },
  {
    title: 'Metadata Changer',
    text: '',
    src: "3.png",
    imageAlt: "3.png",
    position: "left",
    points: [
      "Modifies metadata of cloned image on demand",
      "Pick custom metadata date and time",
      "Bypass shadow bans",
    ],
    icon: BsCardChecklist,
  },
]

