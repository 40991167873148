import React from 'react';
import { Box, Image, Text, Flex, chakra } from '@chakra-ui/react';

const BlogHeader = ({ imageUrl, title, date }) => {
  return (
    <Box w="100%" maxW="900px" mx="auto">
      <Flex direction="column" p={4}>
        <chakra.h1 fontSize="4xl" fontWeight="bold" mb={2} lineHeight="shorter" textAlign="center">
          {title}
        </chakra.h1>
        <Text fontSize="lg" color="gray.600" textAlign="center">
          {date}
        </Text>
      </Flex>
      <Image src={imageUrl} alt={title} w="100%" h="auto" maxH="500px" objectFit="cover" borderRadius={20}/>
    </Box>
  );
};

export default BlogHeader;
