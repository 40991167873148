import axios from 'axios'
import version from "../version"

let appVersion = ""

const noInterceptorAxios = axios.create({ });

export const setAccessTokenHeader = token => {
    axios.defaults.headers.common['x-access-token'] = token // for all requests  
}

let hasInternetConnectionFunc

export const setInternetChecker = (func) => {
    hasInternetConnectionFunc = func
}

export const setAppVersion = (version) => {
    appVersion = version
}

// axios.defaults.baseURL = "http://192.168.1.164:4000"

function safeGetOwnPropertyNames(err) {
    try {
      return JSON.stringify(err, Object.getOwnPropertyNames(err))
    } catch (error) {
      return  JSON.stringify(error, Object.getOwnPropertyNames(error))
    }
}
  
export const postAPIWrapper = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            url,
            data
        ).then((response) => {
            resolve(response)
        }).catch(async (error) => {
            const noInternetConnection = (hasInternetConnectionFunc && !(await hasInternetConnectionFunc()))
            if (noInternetConnection) resolve({data: { success: false, message: "No Internet Connection" }})
            if (error.response && error.response.data) resolve(error.response.data.message ? error.response : resolve({data: { success: false, message: "Failed" }}))
            else {
                logAPI("Error making post request", "postAPIWrapper",  "error", {url, data, error: safeGetOwnPropertyNames(error)})
                resolve({data: { success: false, message: "Failed, please try again." }})
            }
        });
    })
}

export const getAPIWrapper = (url, paramsData) => {
    return new Promise((resolve, reject) => {
        axios.get(url,
        { params: paramsData }
        ).then((response) => {
            resolve(response)
        }).catch(async (error) => {
            const noInternetConnection = (hasInternetConnectionFunc && !(await hasInternetConnectionFunc()))
            if (noInternetConnection) resolve({data: { success: false, message: "No Internet Connection" }})
            if (error.response && error.response.data) resolve(error.response.data.message ? error.response : resolve({data: { success: false, message: "Failed" }}))
            else {
                logAPI("Error making get request", "getAPIWrapper",  "error", {url, paramsData, error: safeGetOwnPropertyNames(error)})
                resolve({data: { success: false, message: "Failed, please try again." }})
            }
        });
    })
}

export const putAPIWrapper = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.put(
            url,
            params
        ).then((response) => {
            resolve(response)
        }).catch(async (error) => {
            const noInternetConnection = (hasInternetConnectionFunc && !(await hasInternetConnectionFunc()))
            if (noInternetConnection) resolve({data: { success: false, message: "No Internet Connection" }})
            if (error.response && error.response.data) resolve(error.response.data.message ? error.response : resolve({data: { success: false, message: "Failed" }}))
            else {
                logAPI("Error making put request", "putAPIWrapper",  "error", {url, params, error: safeGetOwnPropertyNames(error)})
                resolve({data: { success: false, message: "Failed, please try again." }})
            }
        });
    })
}

export const deleteAPIWrapper = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            url, 
            { data }
            ).then((response) => {
            resolve(response)
        }).catch(async (error) => {
            const noInternetConnection = (hasInternetConnectionFunc && !(await hasInternetConnectionFunc()))
            if (noInternetConnection) resolve({data: { success: false, message: "No Internet Connection" }})
            if (error.response && error.response.data) resolve(error.response.data.message ? error.response : resolve({data: { success: false, message: "Failed" }}))
            else {
                logAPI("Error making delete request", "deleteAPIWrapper",  "error", {url, data, error: safeGetOwnPropertyNames(error)})
                resolve({data: { success: false, message: "Failed, please try again." }})
            }
        });
    })
}

export const uploadImageToS3API = (url, fd, fileType) => {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', fd);

        const options = {
            headers: {
                'Content-Type': fileType
            }
        }
        
     noInterceptorAxios.put(url, fd, options)
        .then(response => {
            resolve(response)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export const contactAPI = async (name, message, email) => {
    try{
        return await postAPIWrapper('/api/contact', { name, message, email })
    }catch(err){

    }
}

export const logAPI = async (message, method, level, data={}) => {
    const postData = {
        message,
        data: {...data, method },
        level
    }

    try{
        axios.post('/api/log', postData)
    }catch(err){

    }
}