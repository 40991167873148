export const updateBillingFieldsAction = (currentPlanId, status, isExistingStripeCustomer, creditsUsed, totalCredits, planName, planRestrictionError, interval) => {
    return {
        type: types.UPDATE_BILLING_FIELDS,
        payload: { currentPlanId, status, isExistingStripeCustomer, creditsUsed, totalCredits, planName, planRestrictionError, interval }
    }
}

export const incrementTotalCreditsUsedAction = (val) => {
    return {
        type: types.INCREMENT_TOTAL_CLIENTS,
        payload: { val } 
    }
}

export const types = {
    UPDATE_BILLING_FIELDS: 'billing:updateBillingFields',
    INCREMENT_TOTAL_CLIENTS: 'billing:incrementTotalClients',
}

