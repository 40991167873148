import React, { useContext, useState } from "react";
import Spinner from '../spinner/Spinner'
import authAPI from "../../lib/API/authAPI"
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { updateUserInfoAction } from "../../lib/redux/actions/userActions"
import Input from "../Input/Input";
import InlineError from "../InlineError/InlineError";
import config from "../../lib/config/index"
import { useSelector } from "react-redux";
import { uploadProfileImage } from "lib/helper/authHelper";
import ChangePasswordSection from "Components/Settings/ChangePasswordSection";
import { ThemeContext } from "Context/ThemeContext";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

var heic2any = require("heic2any")

export default function SettingsPage({ fullName }) {
    const { theme } = useContext(ThemeContext);
    const [fullNameInput, setFullNameInput] = useState(fullName);
    const [fullNameError, setFullNameError] = useState("");
    const [fileInputId] = useState(Math.random().toString(36).substr(2, 9))
    const [heicConverting, setHicConverting] = useState(false)
    const [profileImageLoading, setProfileImageLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const profileImageUrl = useSelector( (state) => state.user.info.profileImageUrl);

    const dispatch = useDispatch()

    const onSubmitHandler = async () => {
        if (loading) return
        setLoading(true)
        setFullNameError("")

        let res = await authAPI.updateUserAPI(fullNameInput)

       if (res.data.success) {
           toast.success("Succesfully updated");

           dispatch(updateUserInfoAction({fullName: fullNameInput}))
           
       } else if (res.data.errors) {
           setFullNameError(res.data.errors.fullName)
       } else {
           toast.warning("Failed to updated");
       }

        setLoading(false)
    }
    
    const mediaFileChaged = async event => {
        if (profileImageLoading) return 
        setProfileImageLoading(true)

        let file = event.target.files[0]
        document.getElementById(fileInputId).value = ""

        if (!file) toast.error("Failed to upload")
        else if (!file.type.includes("image")) toast.error("Invalid file format")
        else if (file.size > 20000000) toast.error("File too big", true) //TODO:: fix size - should match s3 bucket object limit
        else if (file.type === 'image/heic'){
            try{
                setHicConverting(true)
                let filename = file.name
                // API.logAPI("Convering HEIC to JPEG - service image", "mediaFileChaged", 'warn', {fileSize: file.size})
                file = await heic2any({ blob: file, toType: "image/jpeg", quality: 1 })
                file.name = filename
                setHicConverting(false)
            }catch(err){
                setHicConverting(false)
                document.getElementById(fileInputId).value = ""
                // onUpdateMessage("Failed to upload image, please try again.", true)
                return
            }
        }

        const error = await uploadProfileImage(file, file.type, file.name)

        if (error) toast.error(error)
        else toast.success("Profile image updated succesfully");

        setProfileImageLoading(false)   
    }

    const onChangeProfileHandler = () => {
        const element = document.getElementById(fileInputId)
        element.click()
    }

    return (
        <Box maxWidth='400px' m='auto'> 
            <input multiple accept='image/*, image/heic' style={{display: "none"}} type="file" id={fileInputId} onChange={mediaFileChaged} disabled={profileImageLoading} />

            <div style={{ margin: "10px 0", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                <div style={{position: "relative", width: 70, height: 70, borderRadius: 50, overflow: "hidden"}}>
                    <img style={{objectFit: "cover", height: "100%", width: "100%"}} src={profileImageUrl !== "" ? `${config.profile_image_domain}/${profileImageUrl}` : require("../../Images/users.png") } />

                    { (profileImageLoading || heicConverting) &&
                        <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "", opacity: 0.8, background: "rgba(0,0,0,0.4)"}}>
                            <Spinner color="black" width="40px" height="40px" margin="15px 0 0 15px"/>
                        </div>
                    }
                </div>

                <button onClick={onChangeProfileHandler} style={{outline: "none", fontSize: 14, border: "1px solid #008489", padding: "5px 8px", borderRadius: "10px", color: "#008489", margin: "15px 0 0 8px"}}>
                    Change profile picture
                </button>  
            </div>                        

            <Text fontSize='sm' fontWeight={500} mb='2' mt='4'>Full name</Text>
            <Flex align='center' justify='center'>
                <Input value={fullNameInput}  onChange={(e) => setFullNameInput(e.target.value)} />
                <Button ml='4' px='6' textAlign='center' size='md' fontSize='xs' bg={theme.primary} _hover={{ bg: theme.hover }} color={theme.color} isLoading={loading} onClick={onSubmitHandler}>Update Profile</Button>
            </Flex>

            <InlineError text={fullNameError} inlineStyle={{margin: fullNameError && "20px 0 0 0"}}/>

            <Box mt='8'>
                <ChangePasswordSection />
            </Box>
            
        </Box>
    )  
}