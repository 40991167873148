import React, { useContext } from "react";
import { Box, Text, Flex,Divider} from "@chakra-ui/react";
import { ThemeContext } from "Context/ThemeContext";

export default function Footer() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box maxW={1000} m='auto' mt='10'>
      <Divider borderColor='rgba(45, 103, 246, 0.2)' />

      <Flex align='center' p='8' justify='center'>
        <Text color={theme.primary} fontWeight="400" fontSize="sm">
          <a href="mailto:ghostclone.io@gmail.com">ghostclone.io@gmail.com</a>
        </Text>

        <Text color="gray.200" fontWeight="600" fontSize="sm" ml='3'>&copy; 2023 {theme.name}</Text>
      </Flex>
    </Box>
  );
}