import React, { useState, useEffect, useContext } from "react";
import { Link as ReactLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Box, Stack, Link, Heading } from '@chakra-ui/react';
import InlineError from "../../Components/InlineError/InlineError";
import MetaTag from "../../Components/MetaTag";
import { verifyEmailAPI } from "../../lib/API/authAPI"
import { toast } from 'react-toastify';
import { handleAuthSuccess } from "../../lib/helper/authHelper"
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function EmailVerification() {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const onForgotHandler = async e => {
    if (loading) return

    setErrorMessage("")
    setLoading(true)

    const res = await verifyEmailAPI(searchParams.get("token"))

    if (!res.data.success) setErrorMessage(res.data.message ? res.data.message : "Failed to verifiy")
    else {
        toast.success("Email verified successfully")
        handleAuthSuccess(res.data)
        navigate(`/dashboard`, { replace: true });
    }

    setLoading(false)
  };

  useEffect(() => {
    const loadForgetHandler = async () => {
      await onForgotHandler()
    }
  
    loadForgetHandler()
  }, []);

  return (
    <Flex minH={'100vh'} align={{ base: 'flex-start', md: 'center' }} justify='center' bgGradient={`linear(to-bl, ${theme.primary}, ${theme.gradient})`}>
      <MetaTag title="Email Verification"/>
      <Stack spacing={8} py={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '3xl', md: '4xl' }} color='#fff'>Verify your email</Heading>
        </Stack>

        <Box rounded={'2xl'} bg='white' boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <Flex justify="center">
              <Logo isLink />
            </Flex>
            <InlineError text={errorMessage} />
            <ClipLoader size={50} color={"grey"} loading={loading}/>
          </Stack>
        </Box>
        <Box textAlign='center'>
          <Link as={ReactLink} color='#fff' to='/dashboard' fontWeight={600}>Back to your dashboard</Link>
        </Box>
      </Stack>
    </Flex>
  );
}