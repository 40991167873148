import "./App.css";
import React, { useState, useEffect, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Routes } from "react-router-dom";

import { connect } from "react-redux";
import history from "./history";
import { logout } from "./lib/helper/authHelper";

import { setAccessTokenHeader } from "./lib/API/API";
import { authenticateAccessToken } from "./lib/API/authAPI";

import RestrictedRoute from "./RestrictedRoute"

// auth pages
import LoginPage from "./Pages/Auth/LoginPage";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import EmailVerification from "./Pages/Auth/EmailVerification";
import Unsubscribe from "./Pages/Auth/Unsubscribe"

// application pages
import Landing from "./Pages/Landing/Landing";

import BillingSuccessPage from "./Pages/BillingSuccessPage";

import { handleAuthSuccess } from "./lib/helper/authHelper"
import ContactUsPage from "./Pages/NavPages/ContactUsPage";
import PricingPlanPage from "./Pages/NavPages/PricingPlanPage";
import FAQsPage from "./Pages/NavPages/FAQsPage";


import usePageTracking from "./usePageTracking"
import AnalyticMetric from "./Pages/Admin/AnalyticMetric/AnalyticMetric";
import Logo from "Images/Logo";
import Theme from 'Theme';

import ErrorPage from "Pages/ErrorPage";

import PrivacyPage from "Pages/NavPages/PrivacyPage";
import BlogsPage from "Pages/NavPages/BlogsPage";
import BlogPage from "Pages/NavPages/BlogPage";
import MetaTag from "Components/MetaTag";
import Upgrade from './Pages/RestrictedPages/Upgrade';
import Settings from "Pages/RestrictedPages/Settings";

import Dashboard from "Pages/RestrictedPages/Dashboard";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import RegisterPage from "Pages/Auth/RegisterPage";
import { ThemeContext } from "Context/ThemeContext";

AOS.init({
  duration: 800,
  once: true
});

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App(props) {
  const { theme } = useContext(ThemeContext);
  const [initialCallMade, setInitialCallMade] = useState(false);

  usePageTracking()

  const validateUserToken = async () => {
    if (!props.accessToken) return;

    setAccessTokenHeader(props.accessToken);
    const res = await authenticateAccessToken()

    if (!res.data.success) logout();
    else {
      handleAuthSuccess(res.data)
      await sleep(100) // give time for branded sidebar color to kick in
    }
  }

  const validateToken = async () => {
    await validateUserToken();
    setInitialCallMade(true);
  };


  useEffect(() => {
    validateToken()
  }, []);

  return (
    <div>
      <MetaTag
        title={"GhostClone | #1 Unique Clones Generator"}
        desc={"Clone & Upload The Same Image To Any System Unlimited Times!"}
        ogImage={"https://ghosthub.io/og-image.png"}
      />
        
      {initialCallMade && 
        <Routes>
          <Route path="/" element={<Landing />}></Route>

          {/* Admin Routes */}
          {/* <Route path="/admin/metrics" element={<AnalyticMetric />}></Route> */}
          
          {/* Nav Pages */}
          {/* <Route path="/contact" element={<ContactUsPage />}></Route>
          <Route path="/pricing" element={<PricingPlanPage />}></Route>
          <Route path="/FAQs" element={<FAQsPage />}></Route> */}
          {/* <Route path="/privacy" element={<PrivacyPage />}></Route> */}
          {/* <Route path="/about" element={<AboutPage />}></Route> */}

          <Route path="/auth/login" element={<LoginPage />}></Route>
          <Route path="/auth/register" element={<RegisterPage />}></Route>
          <Route path="/auth/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/auth/reset-password" element={<ResetPassword />}></Route>
          <Route path="/auth/email-verification" element={<EmailVerification/>}></Route>
          <Route path="/unsubscribe" element={<Unsubscribe/>}></Route>
          
          <Route path="/blog" element={<BlogsPage/>}></Route>
          <Route path="/blog/:blog_id" element={<BlogPage/>}></Route>

          <Route path="/dashboard" element={<RestrictedRoute><Dashboard /></RestrictedRoute>}></Route>
          <Route path="/upgrade" element={<RestrictedRoute><Upgrade /></RestrictedRoute>}></Route>
          {/* <Route path="/notifications" element={<RestrictedRoute><NotificationsPage /></RestrictedRoute>}></Route> */}
          {/* <Route path="/settings" element={<RestrictedRoute><Settings /></RestrictedRoute>}></Route> */}
          {/* <Route path="/settings/:settings_type" element={<RestrictedRoute><Settings /></RestrictedRoute>}></Route>    */}


          <Route path="/payment-success" element={<BillingSuccessPage />}></Route>


          {/* 404 page */}
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
      }

      { !initialCallMade &&
        <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#1a1a1a" }}>
          <Flex align='center'>
            <Spinner color='#fff' />
            <Text color='#fff' ml='4'>Loading</Text>
          </Flex>
          <div>
            <div style={{ marginTop: 30 }}>
              <Logo theme={theme.primary} />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
