import { addToken, removeToken } from "../redux/actions/accessTokenActions";
import reduxStore from '../redux/store'
import { setAccessTokenHeader } from '../API/API'
import authAPI from '../API/authAPI'
import { updateUserInfoAction, updateEmailPrefrencesAction, updateEmailPreferenceAction  } from "../redux/actions/userActions"
import { updateBillingFieldsAction } from "../redux/actions/billingActions"
import { getNotificationsInitialCall } from "./notificationHelper"
import { uploadImageToS3API } from "lib/API/API"
import { updateBrandingAction } from "lib/redux/branding/brandingActions"

const store = reduxStore.store

export const logoutAction = () => {
    return {
        type: 'LOGOUT',
        payload: {}
    }
}

//TODO:: this is duplicated
export const clientLogoutAction = () => {
    return {
        type: 'CLIENT_LOGOUT',
        payload: {}
    }
}

export const updateEmailPreference = async (type, val) => {
    store.dispatch(updateEmailPreferenceAction(type, val))
    await authAPI.updateEmailPreferenceAPI(type, val)
}


export const setAccessToken = token => {
    store.dispatch(addToken(token))
    setAccessTokenHeader(token)
};

export const register = async (fullName, email, password, confirmPassword, fbp, fbc, referralId, formData) => {
    if (password !== confirmPassword) return "Password do not match."

    const res = await authAPI.registerAPI(fullName, email, password, fbp, fbc, referralId, formData)

    if (res.data.success) {
        handleAuthSuccess(res.data)
        return null
    } else {
        return res.data.message
    }  
}

export const login = async (email, password) => {
    const res = await authAPI.loginAPI(email, password)

    if (res.data.success) {
        handleAuthSuccess(res.data)
        return null
    } else {
        return res.data.message
    }
}

export const handleAuthSuccess = (data) => {
    const {token, uid, fullName, email, profileImageUrl, emailVerified, billing, emailPreference, branding} = data

    store.dispatch(updateBrandingAction(branding.brandColor, branding.sidebarColor, branding.name, branding.logoKey))

    if (token) setAccessToken(token)

    if (process.env.REACT_APP_WEB) {
        heap.identify(uid);
        heap.addUserProperties({ uid: uid, Name: fullName, Email: email, Verified: emailVerified, Plan: billing.planName });
    }

    store.dispatch(updateUserInfoAction({uid, fullName, email, profileImageUrl, emailVerified}))

    if (emailPreference) store.dispatch(updateEmailPrefrencesAction(emailPreference))

    // getNotificationsInitialCall()

    initBillingInfo(billing)
}

export const initBillingInfo = (billing) => {
    if (!billing) return
    
    const { currentPlanId, status, isExistingStripeCustomer, creditsUsed, totalCredits, planName, planRestrictionError, interval } = billing
    store.dispatch(updateBillingFieldsAction(currentPlanId, status, isExistingStripeCustomer, creditsUsed, totalCredits, planName, planRestrictionError, interval))
}

export const logout = () => {
    store.dispatch(removeToken())
    store.dispatch(logoutAction())
    store.dispatch(clientLogoutAction()) //this is just to reset loaded client data
}

export const uploadProfileImage = async (file, fileType, filename, customS3Send = false) => {            
    try{
        let preassigned_url_res = preassigned_url_res = await authAPI.getPreassignedUrlAPI(filename, fileType)
    
        if (!preassigned_url_res.data.success) return "Uploading failed, please try again."

        const { key, url } = preassigned_url_res.data

        let s3Res

        if (customS3Send) s3Res = await customS3Send(url, file, fileType)
        else s3Res = await uploadImageToS3API(url, file, fileType)

        if (s3Res.status !== 200) return "Uploading failed, please try again."
        
        let update_res = await authAPI.updateProfileImageAPI(key)

        if (update_res.data.success) {
            store.dispatch(updateUserInfoAction({profileImageUrl: key}))
        } else {
            return "Uploading failed, please try again."
        }

    }catch(err){
        //TODO:: log error
        return "Uploading failed, please try again."
    }
}

export const updateBranding = async (file, name, brandColor, sidebarColor, oldKey) => {
    try{
        if (file) {
            let preassigned_url_res = await authAPI.getBrandingUrlAPI(file.type)
    
            if (!preassigned_url_res.data.success) return "Uploading failed, please try again."

            const { key, url } = preassigned_url_res.data

            let s3Res = await uploadImageToS3API(url, file, file.type)
            if (s3Res.status !== 200) return "Uploading failed, please try again."
            
            let update_res = await authAPI.updateBrandingAPI(name, key, brandColor, sidebarColor)

            if (update_res.data.success) store.dispatch(updateBrandingAction(brandColor, sidebarColor, name, key))
            else return "Uploading failed, please try again."
            
        } else {
            await authAPI.updateBrandingAPI(name, oldKey, brandColor, sidebarColor)
            store.dispatch(updateBrandingAction(brandColor, sidebarColor, name, oldKey))
        }

    }catch(err){
        //TODO:: log error
        return "Uploading failed, please try again."
    }
}