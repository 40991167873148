import { types } from "../actions/collapseSidebarActions";

export default function collapseSidebarReducer(state = [], {type, payload}){
  switch (type) {
    case types.SET_COLLAPSE_SIDEBAR: {
      return payload.collapseSidebar
    }

    default:
      return state
  }
}