import { types } from "../actions/billingActions";

export default function billingReducer(state = [], {type, payload}){
    switch (type){
        case types.UPDATE_BILLING_FIELDS: {
            const { currentPlanId, status, isExistingStripeCustomer, creditsUsed, totalCredits, planName, planRestrictionError, interval } = payload
            return {
                ...state,  
                currentPlanId: ("currentPlanId" in payload) ? currentPlanId : state.currentPlanId,
                status: ("status" in payload) ? status : state.status,
                isExistingStripeCustomer: ("isExistingStripeCustomer" in payload) ? isExistingStripeCustomer : state.isExistingStripeCustomer,
                creditsUsed: ("creditsUsed" in payload) ? creditsUsed : state.creditsUsed,
                totalCredits: ("totalCredits" in payload) ? totalCredits : state.totalCredits,
                planName: ("planName" in payload) ? planName : state.planName,
                planRestrictionError: ("planRestrictionError" in payload) ? planRestrictionError : state.planRestrictionError,
                interval: ("interval" in payload) ? interval : state.interval,

            
            }
        }

        case types.INCREMENT_TOTAL_CLIENTS: {
            const { val } = payload
            return {
                ...state,
                creditsUsed: state.creditsUsed + val
            }
        }

        case types.DECREMENT_TOTAL_CLIENTS: {
            return {
                ...state,
                creditsUsed: state.creditsUsed - 1
            }
        }


        default:
            return state
    }
}