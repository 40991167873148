import React from "react";
import { Helmet } from "react-helmet";

export default function MetaTag({ title, desc, ogImage, keywords, author, canonicalUrl, isBlogPost }) {
  return (
    <Helmet>
       {/* General SEO Meta Tags */}
       <title>{title}</title>
      { desc && <meta name="description" content={desc} /> }
      {keywords && <meta name="keywords" content={keywords} />}
      {author && <meta name="author" content={author} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {/* Add more general SEO meta tags as needed */}

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      { desc && <meta property="og:description" content={desc} /> }
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:url" content="https://ghostclone.io/" />
      <meta property="og:image" content="https://ghostclone.io/og-image.png" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="GhostClone" />

      {isBlogPost ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      { desc && <meta name="twitter:description" content={desc} /> }
      {/* <meta name="twitter:site" content="@ghostclone" />
      <meta name="twitter:creator" content="@ghostclone" />
      <meta name="twitter:image" content="https://ghostclone.io/og-image.png" />
      <meta name="twitter:image:alt" content="GhostClone Logo" /> */}
      {/* Add more Twitter-specific meta tags as needed */}

      {/* Pinterest Meta Tags */}
      <meta name="pinterest-rich-pin" content="true" />
    </Helmet>
  );
}
