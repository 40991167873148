import React, { useContext, useEffect  } from "react";
import { Link as ReactLink, useSearchParams } from 'react-router-dom';
import { Flex, Box, Stack, Heading, Link, Text, Center } from '@chakra-ui/react';
import MetaTag from "../../Components/MetaTag";
import { unsubscribeAPI } from "../../lib/API/authAPI"
import Theme from 'Theme';
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function ResetPassword() {
  const { theme } = useContext(ThemeContext)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initialCall = async () => {
      await unsubscribeAPI(searchParams.get("uid"), searchParams.get("type"))
    }
  
    initialCall()
  }, []);

  return (
    <Flex minH={'100vh'} align={{ base: 'flex-start', md: 'center' }} justify='center' bgGradient={`linear(to-bl, ${theme.primary}, ${theme.gradient})`}>
      <MetaTag title="Unsubscribe"/>
      <Stack spacing={8} py={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '2xl', md: '3xl' }} color='#fff'>Unsubscribing from email list</Heading>
        </Stack>

        <Box rounded={'2xl'} bg='white' boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <Flex justify="center">
              <Logo isLink />
            </Flex>
            <Center>
              <Text color='green.500'>Successfully unsubscribed from {theme.name}</Text>
            </Center>
          </Stack>
        </Box>
        <Box textAlign='center'>
          <Link as={ReactLink} color='#fff' to='/' fontWeight={600}>Back to {theme.name}</Link>
        </Box>
      </Stack>
    </Flex>
  );
}