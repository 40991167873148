// 1. Import `extendTheme`
import {
  extendTheme
} from "@chakra-ui/react";

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  fonts: {
    heading: `'Lexend Deca', 'Inter', 'Open Sans', 'Nunito', 'Poppins', 'Mulish', sans-serif`,
    body: `'Lexend Deca', 'Inter', 'Open Sans', 'Nunito', 'Poppins', 'Mulish', sans-serif`,
  },

  colors: {},
});