export const clientInitialStore = {
  workouts: [],
  photos: [],
  metrics: [],
  notes: [],
  enabledFeatures: {},
  trainingDayMealPlan: [],
  restDayMealPlan: [],
  restricted: false,
  initialCalls: {
      main: { requested: false, loading: false, error: null },
      photos: { requested: false, loading: false, isRefreshLoading: false, error: null, lastRequest: null },
      measurements: { requested: false, loading: false, isRefreshLoading: false, error: null, lastRequest: null },
      meals: { requested: false, loading: false, isRefreshLoading: false, error: null, lastRequest: null },
      notes: { requested: false, loading: false, isRefreshLoading: false, error: null, lastRequest: null },
      features: { requested: false, laoding: false, error: null } 
  },
  macrosGoal: { carbs: 0, fats: 0, protein: 0, notes: ''},
  restDayMacrosGoal: { carbs: 0, fats: 0, protein: 0, notes: ''},
  info: {
      name: '',
      email: '',
      id: '',
      profileImageUrl: '',
  }
}

export const userInitialStore = {
  initialCalls: {
      test: { requested: false, loading: false }
  },
  info: {
      fullName: '',
      email: '',
      id: '',
      profileImageUrl: "",
      emailVerified: "",
      id: "",
      currentPlanId: 0
  },
  emailPreference: {
      feedback: true,
      reminder: true,
      product: true,
      news: true
  }
}

export const billingInitialStore = {
  currentPlanId: 0,
  isExistingStripeCustomer: false,
  status: '',
  creditsUsed: 0,
  totalCredits: 0,
  planName: '',
  planRestrictionError: '',
  interval: ''
}

export const messagingInitialStore = {
  selectedChannel: null,
  initialChannelsCallMade: false,
  initialChannelsLoading: false,
  initialMessagesLoaded: false,
  isRefreshLoading: false,
  totalUnreadMessages: 0,
  lastRequest: null,
  channels: [],
  miniChat: {
    isOpen: false,
    selectedChannel: ''
  },
  botMiniChat: {
    isOpen: false,
    selectedChannel: ''
  }
}