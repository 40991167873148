import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Spinner, // Import Spinner for loading indication
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Icon,
  CloseButton,
  VStack,
  Text,
  IconButton,
  Progress,
} from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import heic2any from "heic2any";
import { getPreSignedUrlAPI, addOrderAPI } from "lib/API/orderAPI";
import { uploadImageToS3API } from "lib/API/API";
import { useSelector, useDispatch } from 'react-redux';
import { incrementTotalCreditsUsedAction } from "lib/redux/actions/billingActions";
import { ThemeContext } from "Context/ThemeContext";
import { toast } from "react-toastify";

function OrderForm({ onClose, onAddOrder }) {
  const { theme } = useContext(ThemeContext);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false); // State for loading indication
  const [isLoading, setIsLoading] = useState(false); // State for loading indication
  const fileInputRef = React.createRef();

  const dispatch = useDispatch()

  const billing = useSelector(state => state.billing);
  const { creditsUsed, totalCredits } = billing;


  const creditsAvailable = totalCredits - creditsUsed; // Calculate credits available
  const hasSufficientCredits = creditsAvailable >= quantity;  // Determine if user has sufficient credits

  const handleImageChange = async (event) => {
    setIsPhotoLoading(true);

    let selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === "image/heic") {
        const convertedBlob = await heic2any({ blob: selectedFile, toType: "image/jpeg", quality: 1 });
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setFile(new File([convertedBlob], "converted.jpeg", { type: "image/jpeg" }));
          setIsPhotoLoading(false);
        };
        reader.readAsDataURL(convertedBlob);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setFile(selectedFile);
          setIsPhotoLoading(false);
        };
        reader.readAsDataURL(selectedFile);
      }
    }

    setIsPhotoLoading(false); // No file selected, set loading to false
  };

  const handleRemoveImage = () => {
    setImage(null);
    setFile(null);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const mongoObjectId = function () {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  };
  
  const customAlert = () => {
    toast('❌ Uploading failed, please try again.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const onSubmit = async () => {
    try {
      if (isLoading) return; 
      setIsLoading(true);

      const id = mongoObjectId()
      const preassigned_url_res = await getPreSignedUrlAPI(id, file.type)

      if (!preassigned_url_res.data.success) return customAlert();

      const { key, url } = preassigned_url_res.data

      let s3Res = await uploadImageToS3API(url, file, file.type)
      if (s3Res.status !== 200) return customAlert();
      
      let add_order_res = await addOrderAPI(id, name, quantity, file.type, file.name)

      if (add_order_res.data.success) {
        onAddOrder(name, id, quantity)
        dispatch(incrementTotalCreditsUsedAction(quantity));
        onClose();
      } else {
        return customAlert();
      } 
    } catch(err) {
      customAlert();
      setIsLoading(false);  
    }

    setIsLoading(false);
  }

  return (
    <VStack spacing={8} p={5} align="stretch" w="100%" maxW="400px" m="auto" bg="#1a1a1a" borderRadius="lg">
      {isLoading &&
        <Box textAlign='center' mb='4'>
          <Text fontSize='xl' color='#fff' mb='6'><Text as='span' mr='3'>📸</Text>Uploading Image...</Text>
          <Progress isIndeterminate rounded='full' colorScheme='yellow' />
        </Box>
      }

      {!isLoading &&
        <>
          <FormControl>
            <FormLabel color='#fff' fontSize='sm'>Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter a name here'
              autoComplete='off'
              color='gray.200'
              fontSize='sm'
              maxLength={20}
              variant='outline'
              autoFocus={true}
              borderRadius="lg"
              borderColor={theme.primary}
              focusBorderColor={theme.primary}
              // bgColor="gray.100"
            />
          </FormControl>

          <FormControl>
            <FormLabel color='#fff' fontSize='sm'>Photo</FormLabel>
            {image ? (
              <Box position="relative">
                <Image src={image} maxHeight={200} objectFit='contain' alt="Uploaded preview" borderRadius="lg" w="full" h="auto" />
                <IconButton
                  icon={<CloseButton />}
                  onClick={handleRemoveImage}
                  position='absolute'
                  top={0}
                  right={0}
                />
              </Box>
            ) : (
              <Button 
                leftIcon={isPhotoLoading ? <Spinner /> : <Icon as={FaImage} />}
                variant="outline"
                w="full"
                borderColor={theme.primary}
                _hover={{ bg: '#262626' }}
                fontSize='sm'
                fontWeight={500}
                color={theme.primary}
                onClick={triggerFileInput}
                isLoading={isPhotoLoading}
                isDisabled={isPhotoLoading}
              >
                Upload Photo
              </Button>
            )}
            <Input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageChange} display="none" />
          </FormControl>

          <FormControl>
            <FormLabel color='#fff' fontSize='sm'>Quantity: {quantity} (<Text as='span' fontSize="xs" fontStyle='italic' color='#fff'>How many unqiue clones do you want?</Text>)</FormLabel>
            <Slider
              aria-label="Slider"
              value={quantity}
              min={1}
              max={100}
              onChange={(val) => setQuantity(val)}
            >
              <SliderTrack bg="gray.100">
                <SliderFilledTrack bg={theme.primary} />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box color="#1a1a1a" as="span">{quantity}</Box>
              </SliderThumb>
            </Slider>
          </FormControl>

          <Button mt={4} bg={theme.primary} color='#1a1a1a' fontSize='sm' w="full" size="lg" onClick={onSubmit} isDisabled={(!hasSufficientCredits || !name || isLoading)}>
            Submit
          </Button>
        </>
      }

      {!hasSufficientCredits && 
        <Text textAlign='center' mt={2} fontSize="sm" fontWeight={500} color='#FF5757'>Not enough credits for selected quantity.</Text>
      }
    </VStack>
  );
}

export default OrderForm;
