import React, { useState, useEffect, useContext } from "react";
import { getPlansAPI } from "../../lib/API/billingAPI"
import { useSelector } from "react-redux";
import "./Plans.scss"
import ClipLoader from "react-spinners/ClipLoader";
import Plan from "./Plan";
import { Box, Button, Flex, Icon, Image, Switch, Text } from "@chakra-ui/react";
import { ThemeContext } from "Context/ThemeContext";
import { FaRocket } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Plans() {
    const { theme } = useContext(ThemeContext);
    const [plans, setPlans] = useState([{}, {}, {}, {}]);
    const [loading, setLoading] = useState(true);
    const [toggleYearly, setToggleYearly] = useState(false);

    const billing = useSelector((state) => state.billing);

    useEffect(() => {
        setToggleYearly(billing.interval === "yearly")
    }, [billing])

    useEffect(() => {
        (async () => {
            const res = await getPlansAPI()

            if (res.data.success) {
                setPlans(res.data.plans)
            } else {

            }

            setLoading(false)    
        })()    
      }, [])

    return (
        <div>
            { !loading &&
                <>
                    <div style={{display: "flex", marginBottom: 40, marginTop: 80, justifyContent: "center"}}>
                        <Flex align="center">
                            <p style={{ fontWeight: toggleYearly ? 500 : 600, color: '#fff' }}>Monthly</p>
                            <Switch 
                                isChecked={toggleYearly}
                                onChange={() => setToggleYearly(!toggleYearly)}
                                size="lg"
                                mx="4"
                                colorScheme='yellow'
                                color={theme.primary}
                            />
                            <Box position='relative'>
                                <Flex align="center">
                                    <p style={{ fontWeight: toggleYearly ? 600 : 500, color: '#fff' }}>Yearly</p>
                                    {/* <Image src={require('../../Images/Landing/gift.png')} transform='rotate(15deg)' width={30} ml={2} /> */}
                                </Flex>
                                <p style={{position: "absolute", background: theme.primary, color: "#1a1a1a", fontSize: 14, textTransform: 'uppercase', fontWeight: 600, padding: '4px 8px', borderRadius: 6, top: -40, left: -25, width: 90}}>Save 20%</p>
                            </Box>
                        </Flex>
                    </div>
            
            
                    <div className="plansContainer">
                        { plans.map((plan, index) => {
                            return <Plan
                                index={index}
                                key={index} 
                                name={plan.name} 
                                planId={plan.planId} 
                                monthly={plan.monthly} 
                                yearly={plan.yearly} 
                                description={plan.description}
                                currencySymbol={plan.currencySymbol}
                                displayYearly={toggleYearly}
                                loading={loading}
                                />
                        })}

                    <Flex flexDirection='column' justify='space-between' bg='#262626' minW='300px' maxW='300px' _hover={{ boxShadow: '2xl', transform: 'scale(1.05)' }} transition="all 0.3s ease-in-out" mb={10} position='relative' textAlign='center' px={6} py='10' border='2px solid #555' boxShadow='lg' rounded='xl'>
                        <Box>
                            <Text fontSize='2xl' fontWeight={700} color='#fff' mb='4'>Agency</Text>

                            <Box boxShadow='xl' width='fit-content' p={5} rounded='full' m='auto' mb={4}>
                                <Icon as={FaRocket} size={30} color={theme.primary} />
                            </Box>
                        </Box>

                        <Text mt='4' color='#fff' fontSize='xl' fontWeight={500}>Contact us for a custom plan if you need more</Text>

                        <Button as='a' href='https://t.me/mrghostc' target="_blank" mt={6} _hover={{ bg: theme.hover, color: 'white' }} bg={'none'} size='md' color={theme.primary} borderWidth={2} borderColor='#555' fontWeight={500} width='100%'>
                            Contact Us
                        </Button>
                    </Flex>
                    </div>
                </>
            }

            { loading &&
                <Flex justify='center' mt={12}>
                    <ClipLoader size={80} color={"#6366F3"} loading={true}/>
                </Flex>
            }
        </div>
    );
}
