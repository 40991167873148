import React from 'react';
import { Box, Image, Flex, chakra, Link, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Section = ({ id, heading, image, text }) => {
  const customRenderers = {
    h1: ({ children }) => <chakra.h1 fontSize="4xl" fontWeight="extrabold" mb={4}>{children}</chakra.h1>,
    h2: ({ children }) => <chakra.h2 fontSize="3xl" fontWeight="bold" my={4}>{children}</chakra.h2>,
    h3: ({ children }) => <chakra.h3 fontSize="2xl" fontWeight="bold" my={3}>{children}</chakra.h3>,
    p: ({ children }) => <Text fontSize="lg" my={2}>{children}</Text>,
    ul: ({ children }) => <UnorderedList pl={4} fontSize="lg">{children}</UnorderedList>,
    li: ({ children }) => <ListItem fontSize="lg">{children}</ListItem>,
    a: ({ href, children }) => (
      <Link
        href={href}
        isExternal
        color="blue.500"
        textDecoration="underline"
        _hover={{
          textDecoration: "none",
          color: "blue.700",
        }}
        fontSize="lg"
      >
        {children}
      </Link>
    ),
  };

  return (
    <Box w="100%" maxW="900px" mx="auto" mt={2} p={2}> {/* Removed bottom margin here */}
      <Flex direction="column" p={4}>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={customRenderers}
        >
          {heading}
        </ReactMarkdown>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={customRenderers}
        >
          {text}
        </ReactMarkdown>
      </Flex>
      {image && image.url && <Image src={require(`./data/${id}/${image.url}`)} alt={image.alt} w="100%" h="auto" maxH="500px" objectFit="cover" />}
    </Box>
  );
};

export default Section;
