import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import { Flex, Box, FormControl, FormLabel, Stack, Link, Button, Heading, Text, InputGroup, InputRightElement, Input } from '@chakra-ui/react';
import * as authHelper from "../../lib/helper/authHelper";
import InlineError from "../../Components/InlineError/InlineError";
import MetaTag from "../../Components/MetaTag";
import config from "../../lib/config/index"
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Cookies from 'js-cookie'
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function RegisterPage() {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = useSelector((state) => state.accessToken);
  let navigate = useNavigate();

  const onRegisterHandler = async e => {
    setLoading(true)
    e.preventDefault()
    e.stopPropagation()

    // Access the referral value directly using querySelector
    const rewardfulReferralElement = document.querySelector('input[name="referral"]');
    const referralId = rewardfulReferralElement ? rewardfulReferralElement.value : '';

    const fbp = Cookies.get("_fbp")
    const fbc = Cookies.get("_fbc")

    const errorMessage = await authHelper.register(name, email, pwd, pwd, fbp, fbc, referralId)

    if (!errorMessage) {
      navigate(`/dashboard`, { replace: true });
    } else {
      setErrorMessage(errorMessage);
    }
    setLoading(false)
  }

  // useEffect for Rewardful
  useEffect(() => {
    const signupForm = document.getElementById('signup-form');

    // Function to add Rewardful form when script is loaded
    const addRewardfulForm = () => {
      if (signupForm && window.Rewardful && window.Rewardful.Forms) {
        window.Rewardful.Forms.add(signupForm);
      }
    };

    // Check if the script is already loaded
    if (window.Rewardful && window.Rewardful.Forms) {
      addRewardfulForm();
    } else {
      // Listen for the script load event
      window.addEventListener('load', addRewardfulForm);
    }

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('load', addRewardfulForm);
    };
  }, []);


  useEffect(() => {
    if (accessToken) {
      navigate(`/dashboard`, { replace: true });
    }
  
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');

    if (emailParam) {
      setEmail(emailParam);
    }
  }, [])

  return (
    <Flex minH={'100vh'} justify='center' align={{ base: 'flex-start', md: 'center' }} bg='#1a1a1a'>
      <MetaTag title={`Register | Online Personal Training Software • ${config.app_name}`} />

      <Stack spacing={8} py={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '3xl', md: '4xl' }} color='#fff'>Sign up</Heading>
          <Text fontSize={'lg'} color='#fff'>
            to start cloning your images 👻
          </Text>
        </Stack>

        <Box id='signup-form' rounded={'2xl'} bg='#262626' boxShadow={'lg'} p={8} as='form' onSubmit={onRegisterHandler} shadow='0 0 25px 3px rgba(251, 191, 86, 0.5)' data-rewardful="true">
          <Stack spacing={4}>
            <Flex justify="center">
              <Logo isLink />
            </Flex>
            <FormControl id="fullname">
              <FormLabel color='#fff'>Full Name</FormLabel>
              <Input variant='outline' color='#fff' onChange={(e) => setName(e.target.value)} value={name} />
            </FormControl>
            <FormControl id="email">
              <FormLabel color='#fff'>Email address</FormLabel>
              <Input variant='outline' color='#fff' type="email" isEmail={true} onChange={(e) => setEmail(e.target.value)} value={email} />
            </FormControl>
            <FormControl id="password">
              <FormLabel color='#fff'>Password</FormLabel>
              <InputGroup>
                <Input variant='outline' color='#fff' type="password" isPassword={!showPassword} onChange={(e) => setPwd(e.target.value)} value={pwd} />
                <InputRightElement h={'full'}>
                  <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack pt={4}>
              <Button type={"submit"} bg={theme.primary} color={'#262626'} _hover={{ bg: theme.hover }} isLoading={loading}>
                Sign up
              </Button>
            </Stack>
            <InlineError text={errorMessage} />
          </Stack>
        </Box>
        <Box textAlign='center'>
          <Link as={ReactLink} color='#fff' to='/auth/login' fontWeight={600}>Already have an account? Sign In</Link>
        </Box>
      </Stack>
    </Flex>
  );
}