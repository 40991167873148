import React, { useState, useEffect, useContext } from 'react';
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  PopoverFooter,
  Flex,
  useDisclosure,
  Collapse
} from '@chakra-ui/react';
import { ThemeContext } from 'Context/ThemeContext';

const DownloadButton = ({ handleDownload, imageNumber }) => {
  const { theme } = useContext(ThemeContext);
  const { isOpen: isOpenInfo, onToggle } = useDisclosure()

  const [currentDateTime, setCurrentDateTime] = useState({
    date: '',
    time: '',
  });

  const formDateFromCurrentDateTime = () => {
    const { date, time } = currentDateTime;
  
    // Validate if the date and time strings are present
    if (!date || !time) {
      console.error('Date or time missing.');
      return null;
    }
  
    // Create a new Date object from the date and time strings
    const formattedDateTimeString = `${date}T${time}`;
    const newDate = new Date(formattedDateTimeString);
  
    // Validate if the formed date is valid
    if (isNaN(newDate.getTime())) {
      console.error('Invalid date or time format.');
      return null;
    }
  
    return newDate;
  };
  

  const onDownload = () => {
    let date = formDateFromCurrentDateTime()
    console.log("DDDD", date)
    handleDownload(imageNumber, formDateFromCurrentDateTime())
    setIsOpen(false)
  }

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const formatDateTime = () => {
      const now = new Date();
      const date = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const time = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
      return { date, time };
    };

    setCurrentDateTime(formatDateTime());
  }, []);

  const addMinutes = (minutesToAdd) => {
    const current = new Date(`${currentDateTime.date}T${currentDateTime.time}`);
    current.setMinutes(current.getMinutes() + minutesToAdd);
    setCurrentDateTime({
      date: `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${String(current.getDate()).padStart(2, '0')}`,
      time: `${String(current.getHours()).padStart(2, '0')}:${String(current.getMinutes()).padStart(2, '0')}`,
    });
  };

  return (
    <Popover placement='right'  isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button mt="4" size="xs" fontSize="xs" colorScheme="whiteAlpha" color="whiteAlpha.900" onClick={() => setIsOpen(true)}>
          Download
        </Button>
      </PopoverTrigger>
      <PopoverContent color="whiteAlpha.900" bg="#262626" minW={{ base: '300px', md: '400px' }} maxW="400px" w="100%" borderColor={theme.primary}>
        {/* <PopoverArrow /> */}
        <PopoverCloseButton />
        <PopoverHeader borderWidth={0} fontWeight={500}>Select Metadata Download Time</PopoverHeader>
        <PopoverBody width='100%'>
          <FormControl mb='4'>
            <FormLabel color='#fff' fontSize='sm'>Date</FormLabel>
            <Input
              type="date"
              id='date'
              value={currentDateTime.date} 
              onChange={(e) => setCurrentDateTime({ ...currentDateTime, date: e.target.value })}
              placeholder='Select a date'
              autoComplete='off'
              color='gray.200'
              fontSize='sm'
              maxLength={20}
              variant='outline'
              autoFocus={true}
              borderRadius="lg"
              borderColor={theme.primary}
              focusBorderColor={theme.primary}
            />
          </FormControl>

          <FormControl mb='4'>
            <FormLabel color='#fff' fontSize='sm'>Time</FormLabel>
            <Input
              type="time" 
              id='time'
              value={currentDateTime.time}
              onChange={(e) => setCurrentDateTime({ ...currentDateTime, time: e.target.value })}
              placeholder='Select a date'
              autoComplete='off'
              color='gray.200'
              fontSize='sm'
              maxLength={20}
              variant='outline'
              autoFocus={true}
              borderRadius="lg"
              borderColor={theme.primary}
              focusBorderColor={theme.primary}
            />
          </FormControl>

          {/* <Text mt={2} fontSize="sm" color="whiteAlpha.700">
            You can manually set the time or use the quick settings below:
          </Text> */}

          {/* <Flex justify='space-between' mt='2'>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={() => addMinutes()}>Now</Button>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={() => addMinutes(1)}>+1 min</Button>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={() => addMinutes(2)}>+2 mins</Button>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={() => addMinutes(5)}>+5 mins</Button>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={() => addMinutes(10)}>+10 mins</Button>
          </Flex> */}


          <Flex align='center'>
            <Text fontSize="sm" fontWeight={500} color="#fff" mr='2'>Important Points:</Text>
            <Button size="sm" fontWeight={400} color='#fff' borderColor={theme.primary} variant='outline' onClick={onToggle}>{isOpenInfo ? 'Hide' : "Show"}</Button>
          </Flex>        

          <Collapse in={isOpenInfo} animateOpacity>
            <Box mt={4}>
              <Box mt={2} pl={3}>
                <Text fontSize="sm" fontWeight="medium" color="whiteAlpha.800">1. Verification Requirement</Text>
                <Text mt={1} fontSize="sm" color="whiteAlpha.700">
                  If verification is required, set the "created time" to match when you intend to use this photo.
                </Text>
              </Box>
              <Box mt={2} pl={3}>
                <Text fontSize="sm" fontWeight="medium" color="whiteAlpha.800">2. Profile Image Tip</Text>
                <Text mt={1} fontSize="sm" color="whiteAlpha.700">
                  For profile images, consider setting the date to some time in the past to make it appear as if the photo was taken earlier.
                </Text>
              </Box>
            </Box>
          </Collapse>

          <Button width='100%' mt='4' size="md" mb='6' color='#262626' bg={theme.primary} fontSize='xs' onClick={onDownload}>Download</Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DownloadButton;
