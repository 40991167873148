import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom

const BlogCTA = () => {
  // Replace this URL with your actual sign-up page URL
  const signUpURL = '/auth/register';

  return (
    <Box bg="gray.100" py={8} px={4} mt={8}>
      <Flex direction="column" align="center">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          TEXT 1
        </Text>
        <Text fontSize="lg" mb={6} textAlign="center">
          TEXT 2
        </Text>
        <Button
          as={Link} // Use the Link component instead of 'a' tag
          to={signUpURL} // Specify the route to which it should redirect
          backgroundColor="#2051f1"
          color="white"
          size="lg"
          fontWeight="bold"
          _hover={{ opacity: '0.8' }}
        >
          Get Started Now
        </Button>
      </Flex>
    </Box>
  );
};

export default BlogCTA;
