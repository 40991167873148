import React from "react";
import Pricing from "Components/Billing/Pricing";
import Sidebar from "Components/Sidebar/Sidebar";

export default function PaymentsPage() {
  return (
    <Sidebar selectedTab={"upgrade"} maxWidth={1400}>
      <Pricing />
    </Sidebar>
  );
}
