import { Box, Button, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { ThemeContext } from "Context/ThemeContext";

export default function Header({ title, emphasis, description, src, SVG, cover = 'orange', opacity = .3, hideContent }) {
  const { theme } = useContext(ThemeContext)
  
  return (
    <Box bg='#fef4ea' overflow='hidden'>
      <Box bg='white' boxShadow='md'>
        <Nav />
      </Box>
      { !hideContent &&
          <Box maxWidth={1420} mx="auto" px={8} py={{ base: 4, md: 24 }} zIndex={10}>
            <Stack direction={{ base: "column", md: "row" }} justify='space-between'>
              <VStack alignItems={{ base: "left", md: "flex-start" }} width={{ base: "100%", md: "40%" }} justifyContent="center" mt={{ base: 12, md: 0 }} pr={{ base: 0, md: 12 }}>
                <Text fontSize={{ base: "32px", md: "60px" }} fontWeight={600} pb={8}>
                  {title}{" "}
                  <Text as="span" color={theme.primary}>{emphasis}</Text>
                </Text>
                <Text fontSize={{ md: "md" }} maxWidth="448px" fontWeight={500} color="gray.700">{description}</Text>

                <Stack pt={10} spacing={4} zIndex={1} direction={{ base: 'column', md: 'row' }} width='100%'>
                  <Button rounded="4px" width='100%' as={Link} display="inline-flex" boxShadow="lg" fontSize={"md"} size="lg" fontWeight={600} color={"white"} bg={theme.primary} to="/auth/register" _hover={{ bg: "#4738AC" }} rightIcon={<FiArrowUpRight />}>
                    Get started free
                  </Button>
                  <Button rounded="4px" width='100%' as={Link} to="/demo" boxShadow="lg" borderWidth={1} borderColor={theme.primary} display="inline-flex" fontSize={"md"} size="lg" fontWeight={600} color={theme.primary} bg={"#fff"} _hover={{ bg: "blue.50" }}>
                    Schedule a demo
                  </Button>
                </Stack>
              </VStack>

              { SVG &&
                <Box height="100%" maxWidth='550px' width={{ base: "100%" }} position='relative'>
                  <SVG />
                  {/* <BackgroundCover type={cover} opacity={opacity} width='850px' height='850px' right='-35%' top='-45%' /> */}
                </Box>
              }

              { src &&
                <Box pt={{ base: 12, md: 0 }} width='100%' maxW='3xl'>
                  <img src={require('../../../Images/Features/' + src)} style={{ width: '100%', borderRadius: 20 }} />
                </Box>
              }
            </Stack>
          </Box>
      }

    </Box>
  );
}
