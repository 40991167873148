export const updateUserInfoAction = data => {
    return {
        type: types.UPDATE_USER_INFO,
        payload: data
    }
}

export const updateEmailPrefrencesAction = (data) => {
    return {
        type: types.UPDATE_EMAIL_PREFERENCES,
        payload: data
    }
}

export const updateEmailPreferenceAction = (type, val) => {
    return {
        type: types.UPDATE_EMAIL_PREFERENCE,
        payload: { type, val }
    }
}

export const types = {
    UPDATE_USER_INFO: 'user:updateUserInfo',
    UPDATE_EMAIL_PREFERENCES: 'user:updateEmailPreferences',
    UPDATE_EMAIL_PREFERENCE: 'user:updateEmailPreference',
}

