import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import AsyncStorage from '@react-native-async-storage/async-storage'; // we dont need this on web, maybe create a second file..

import { userInitialStore, billingInitialStore, messagingInitialStore } from "../initialStores"
import { notificationInitialStore } from "../notificationsRedux/notificationUtils";
import { brandingInitialStore } from "../branding/brandingUtils"
import accessTokenReducer from './access-token-reducer'
import userReducer from './user/userReducer'
import billingReducer from "./billingReducer";
import notificationReducer from '../notificationsRedux/notificationReducer'
import brandingReducer from "../branding/brandingReducer"

//client reducers
import collapseSidebarReducer from "./collapseSidebarReducer";

const whitelist = ['accessToken'];

if (process.env.REACT_APP_WEB !== 'true') {
    whitelist.push('client', 'clientWorkoutPlans', 'exerciseTracker', 'clientForms', 'clientVault', 'branding');
  }

const persistConfig = {
    key: 'root',
    storage: process.env.REACT_APP_WEB ? storage : AsyncStorage,
    whitelist
}

const appReducer = combineReducers(({
    accessToken: accessTokenReducer,
    user: userReducer,
    billing: billingReducer,
    notification: notificationReducer,
    collapseSidebar: collapseSidebarReducer,
    branding: brandingReducer
}))

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = {
            ...state,
            accessToken: null,
            user: userInitialStore,
            billing: billingInitialStore,
            notification: notificationInitialStore,
            messaging: messagingInitialStore,
            branding: brandingInitialStore
        }
    }

    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)