export const updateBrandingAction = (brandColor, sidebarColor, name, key) => {
  return {
      type: types.UPDATE_BRANDING,
      payload: { brandColor, sidebarColor, name, key }
  }
}

export const types = {
  UPDATE_BRANDING: 'branding:updateBillingFields'
}

