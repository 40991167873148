import { getAPIWrapper, postAPIWrapper } from "./API";

export const getOrdersAPI = (offset, limit) => {
  return getAPIWrapper("/api/order", { offset, limit });
};

export const addOrderAPI = (id, name, quantity, fileType, fileName ) => {
  return postAPIWrapper("/api/order", { id, name, quantity, fileType, fileName });
};

export const getPreSignedUrlAPI = (id, fileType) => {
  return getAPIWrapper("/api/order/pre-signed-url", { id, fileType });
};