import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Box, FormControl, FormLabel, Stack, Button, Heading } from '@chakra-ui/react';
import InlineError from "../../Components/InlineError/InlineError";
import MetaTag from "../../Components/MetaTag";
import Input from "Components/Input/Input";
import { resetPasswordAPI } from "../../lib/API/authAPI"
import { toast } from 'react-toastify';
import { handleAuthSuccess } from "../../lib/helper/authHelper"
import Theme from 'Theme';
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function ResetPassword() {
  const { theme } = useContext(ThemeContext);
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const onForgotHandler = async e => {
    if (loading) return
    e.preventDefault()
    e.stopPropagation()

    setErrorMessage("")
    setLoading(true)

    if (pwd !== confirmPwd) {
      setErrorMessage("Please make sure that both passwords match")
      setLoading(false)
      return 
    }

    const res = await resetPasswordAPI(pwd, searchParams.get("token"))

    if (!res.data.success) {
      setErrorMessage(res.data.message ? res.data.message : "Failed to update")
    } else {
      toast.success("Password changed successfully")
      handleAuthSuccess(res.data)
      navigate(`/dashboard`, { replace: true });
    }

    setLoading(false)
   };

  return (
    <Flex minH={'100vh'} align={{ base: 'flex-start', md: 'center' }} justify='center' bgGradient={`linear(to-bl, ${theme.primary}, ${theme.hover})`}>
      <MetaTag title="Reset Password"/>
      <Stack spacing={8} py={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '3xl', md: '4xl' }} color='#fff'>Reset your password</Heading>
        </Stack>

        <Box rounded={'2xl'} bg='white' boxShadow={'lg'} p={8} as='form' onSubmit={onForgotHandler}>
          <Stack spacing={4}>
            <Flex justify="center">
              <Logo isLink />
            </Flex>

            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" isPassword={true} onChange={(e) => setPwd(e.target.value)} value={pwd} />
            </FormControl>
            <FormControl id="confirmPassword">
              <FormLabel>Confirm Password</FormLabel>
              <Input type="password" isPassword={true} onChange={(e) => setConfirmPwd(e.target.value)} value={confirmPwd} />
            </FormControl>
            <Stack pt={4}>
              <Button type={"submit"} bg={theme.primary} color={'white'} _hover={{ bg: theme.hover }} isLoading={loading}>
                Reset Password
              </Button>
            </Stack>
            <InlineError text={errorMessage} />
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}