import React from 'react';
import { Box, Grid } from '@chakra-ui/react';
import BlogCard from './BlogCard';

const BlogGrid = ({ data, grid }) => {
  const getMaxWidth = () => {
    return grid === 2 ? '600px' : '400px';
  };

  return (
    <Box p={4} mx="auto">
      <Grid templateColumns={{ base: '1fr', md: `repeat(${grid}, 1fr)` }} gap={4} justifyItems="center">
        {data.map((blog) => (
          <BlogCard key={blog.id} id={blog.id} title={blog.title} date={blog.date} width={getMaxWidth()} />
        ))}
      </Grid>
    </Box>
  );
};

export default BlogGrid;
