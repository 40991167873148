export const setUserRequestedInitialCall = (key, value) => {
    return {
        type: types.SET_REQUESTED_INITIAL_CALL,
        payload: { key, value }
    }
}

export const setUserLoadingInitialCall = (key, value) => {
    return {
        type: types.SET_LOADING_INITIAL_CALL,
        payload: { key, value }
    }
}

export const setUserErrorInitialCall = (key, value) => {
    return {
        type: types.SET_ERROR_INITIAL_CALL,
        payload: { key, value }
    }
}

export const types = {
    SET_REQUESTED_INITIAL_CALL: 'user_initialCalls:requested',
    SET_LOADING_INITIAL_CALL: 'user_initialCalls:loading',
    SET_ERROR_INITIAL_CALL: 'user_initialCalls:error'
}

