import React, { useState } from 'react';
import { useSelector } from "react-redux";
import SuccessFailMessage from '../SuccessFailMessage/SuccessFailMessage';

// chakra
import { Box, Icon, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';

// icons
import { FaDumbbell, FaImages, FaListUl, FaRuler, FaHamburger, FaRobot } from "react-icons/fa";
import { FiMessageCircle } from "react-icons/fi";
import { BiLibrary } from "react-icons/bi"
import TopNav from './TopNav';
import SidebarContent from './SidebarContent';
import MetaTag from "../MetaTag";
import config from "lib/config/index"
import { RiSafe2Fill } from 'react-icons/ri';
import { useEffect } from 'react';
import { requestNotificationPermission } from "lib/helper/browserNotificationHelper"

export default function Sidebar({ children, selectedTab, maxWidth, metaTagName, backTo, fullScreen }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userInfo = useSelector((state) => state.user.info);

  const planRestrictionError = useSelector((state) => state.billing.planRestrictionError)

  useEffect(() => {
    requestNotificationPermission()
  }, [])

  return (
    <Box minH="100vh" bg='#1a1a1a' overflow='hidden'>
      { metaTagName && <MetaTag title={`${metaTagName} • ${config.app_name}`} /> }

      {/* top nav for desktop & mobile */}
      {/* { !fullScreen && <TopNav isOpen={isOpen} onOpen={onOpen} userInfo={userInfo} backTo={backTo}/> } */}

      {/* children content */}
      <Box transition=".4s ease" ml={{ base: 0, md: 0 }} p={fullScreen ? 0 : 4}>
        <Box maxWidth={maxWidth ? maxWidth : 1350} mx='auto' pb={fullScreen ? 0 : { base: 6, sm: 0 }}>
            { (planRestrictionError && !fullScreen) &&
              <div style={{marginBottom: 20}}>
                <SuccessFailMessage fail={true} text={planRestrictionError} />
              </div>
            }
          {children}
        </Box>
      </Box>
    </Box>
  );
}


