import React, { Component } from 'react';
import './SuccessFailMessage.scss';

class SuccessFailMessage extends Component {
    render() {
        return (
            <div className="successFailMessage-component">
                <p style={{margin: 0}} className={this.props.fail ? "fail" : "success"}>{this.props.text}</p>
            </div>
        )
    }
}

export default SuccessFailMessage
