import { types } from "./notificationActions";

export default function notificationReducer(state = [], {type, payload}){

    switch (type){
        case types.SET_INITIAL_CALL_REQUESTED: {
            return {
                ...state,
                initialCall: { ...state.initialCall, requested: true }
            }
        }

        case types.UPDATE_INITIAL_CALL_LOADING: {
            return {
                ...state,
                initialCall: { ...state.initialCall, loading: payload.val }
            }
        }

        case types.UPDATE_INITIAL_CALL_ERROR: {
            return {
                ...state,
                initialCall: { ...state.initialCall, error: payload.error }
            }
        }

        case types.ADD_NOTIFICATIONS: {
            return {
                ...state,
                notifications: payload.notifications.map(({ _id, title, description, imageSrc, webPath, read, date, clientName }) => {
                    return { id: _id, title, description, imageSrc, webPath, read, date, clientName }
                })
            }
        }

        case types.ADD_NOTIFICATION: {
            const { id, title, description, imageSrc, webPath, read, date, clientName } = payload
            return {
                ...state,
                notifications: [
                    { id, title, description, imageSrc, webPath, read, date, clientName },
                    ...state.notifications,
                ]
            }
        }

        case types.READ_NOTIFICATION: {
            const { notificationId } = payload
            return {
                ...state,
                notifications: state.notifications.map((notification) => {
                    if (notification.id === notificationId) return { ...notification, read: true }
                    else return notification
                })
            } 

        }

        case types.READ_ALL_NOTIFICATIONS: {
            return {
                ...state,
                notifications: state.notifications.map((notification) => {
                    return { ...notification, read: true }
                })
            }
        }

        case types.UPDATE_PREFERENCES: {
            return {
                ...state,
                preference: {
                    forms: payload.forms,
                    progressPhotos: payload.progressPhotos,
                    workoutCompletion: payload.workoutCompletion,
                    metrics: payload.metrics,
                    messages: payload.messages
                }
            }

        }

        case types.UPDATE_PREFERENCE: {
            const { type, email, notification } = payload
            return {
                ...state,
                preference: {
                    ...state.preference,
                    [type]: {
                        email: email,
                        notification: notification
                    }
                }
            }
        }


        default:
            return state
    }
    
}