import React, { useContext } from "react";
import { Input as InputC, FormControl, FormLabel, FormErrorMessage, Textarea, Text, Flex, Tooltip, Icon } from "@chakra-ui/react";
import { BiHelpCircle } from "react-icons/bi";
import { ThemeContext } from "Context/ThemeContext";

export default function Input({ id, value, defaultValue, disabled, placeholder, tooltip, optional = false, optionalMessage = '(optional)', onChange, onSubmit = () => {}, style, label, isPassword, isFile, isNumber, isEmail, autoFocus, textarea, wrapperStyle, isInvalid, errorMessage, isRequired, focusRef, ...props }) {  
  const { theme } = useContext(ThemeContext);

  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} textarea={true}>
      <Flex align='center'>
        {label && <FormLabel fontSize='sm' style={{marginBottom: 0}}>{label} {optional && <Text fontSize='xs' as='span' fontStyle='italic' color='gray.500' fontWeight={400}>{optionalMessage}</Text>}</FormLabel>}
        {tooltip &&
          <Tooltip fontSize='xs' label={tooltip} placement='right' hasArrow arrowSize={8} ml={1} arrowPadding={4} openDelay={200}>
            <Icon w='5' h='5' variant="ghost" aria-label="open menu" icon={<BiHelpCircle />} />
          </Tooltip>
        }
      </Flex>
      {!textarea &&
        <InputC
          id={id}
          ref={focusRef}
          value={value} 
          {...props}
          defaultValue={defaultValue} 
          onChange={onChange} 
          onKeyPress={e=> {
            if (e.key === 'Enter') {
               onSubmit();
            }
         }}
          disabled={disabled} isPassword={isPassword} type={isEmail ? "email" : isPassword ? 'password' : isNumber ? "number" : isFile ? "file" : 'text'}
          placeholder={placeholder} 
          size='sm' py={5} pl={2} my={1} autoFocus={autoFocus} style={{ ...style, width: "100%" }}
          variant='filled' focusBorderColor={theme.primary}
          rounded='md'
        />
      }

      {textarea &&
        <Textarea
          disabled={disabled}
          className={"input"}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          size='sm' pl={2} my={1} autoFocus={autoFocus} style={{ ...style, width: "100%" }}
          variant='filled' focusBorderColor={theme.primary}
          rounded='md'
        />
      }

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}