export const addToken = token => {
    return {
        type: types.ADD_TOKEN,
        payload: { 
            token
         }
    }
}

export const removeToken = () => {
    return {
        type: types.REMOVE_TOKEN,
        payload: {}
    }
}

export const types = {
    ADD_TOKEN: 'token:addToken',
    REMOVE_TOKEN: 'token:removeToken'
}

