import React, { useCallback, useContext, useRef, useState } from 'react';
import {
  VStack,
  Flex,
  Wrap,
  WrapItem,
  Text,
  Box,
  Image,
  Button,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  FormControl,
  FormLabel,
  Input,
  PopoverFooter,
} from "@chakra-ui/react";
import config from "lib/config/index";
import { ThemeContext } from 'Context/ThemeContext';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Progress } from "@chakra-ui/react";
import ReactCanvasConfetti from "react-canvas-confetti";
import piexif from "piexifjs";
import { Buffer } from 'buffer/';
import DownloadButton from "./DownloadButton"

function Pagination({ currentPage, totalItems, pageSize, onPageChange }) {
  const { theme } = useContext(ThemeContext);
  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <Flex align='center' justify='space-between' width='100%' pt='8'>
      <Button px='6' bg={theme.primary} fontSize='sm' size='sm' onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 1}>Prev</Button>
      <Text color='#fff'>Page {currentPage} of {totalPages}</Text>
      <Button px='6' bg={theme.primary} fontSize='sm' size='sm' onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage === totalPages}>Next</Button>
    </Flex>
  );
}

const ViewOrder = ({ quantity, id, name, extension, progress, onClose }) => {
  const { theme } = useContext(ThemeContext);
  const baseUrl = `${config.order_image_domain}/${id}`;
  const [loadingImages, setLoadingImages] = useState([]);  // Initialize as an empty array

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = quantity;

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const addMetadataToImage = async (imageBuffer, date) => {
    try {
      let zeroth = {};
      let exif = {};
      let gps = {};

      console.log("DATE", date)
  
      const localDateStr = date.toLocaleDateString().replace(/\//g, '-');
      const localTimeStr = date.toLocaleTimeString();
      const dateFormat = `${localDateStr} ${localTimeStr}`;
  
      // General metadata
      zeroth[piexif.ImageIFD.Make] = "Samsung";
      zeroth[piexif.ImageIFD.Model] = "Galaxy S21 Ultra";
      zeroth[piexif.ImageIFD.Software] = "Android 12";
      zeroth[piexif.ImageIFD.DateTime] = dateFormat;
  
      // EXIF data
      exif[piexif.ExifIFD.DateTimeOriginal] = dateFormat;
      exif[piexif.ExifIFD.ExposureTime] = [1, 60];
      exif[piexif.ExifIFD.FNumber] = [19, 10]; // FNumber is 1.9
  
      // Remove GPS Data
      gps = {};
  
      // Create the EXIF bytes
      const exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
      const exifbytes = piexif.dump(exifObj);
  
      // Insert EXIF back to the image
      const newData = piexif.insert(exifbytes, imageBuffer.toString("binary"));
      const newBuffer = Buffer.from(newData, "binary");
  
      return newBuffer;
  
    } catch (error) {
      console.error('Failed to add metadata to image:', error);
      return imageBuffer; // Return the original buffer if metadata addition fails
    }
  };
  

  const handleDownload = async (number, date) => {
    try {
      setLoadingImages((prevImages) => [...prevImages, number]);
    const response = await fetch(`${baseUrl}/${number}.${extension}`);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = function () {
      const imageBuffer = Buffer.from(reader.result);
      addMetadataToImage(imageBuffer, date)
        .then((newBuffer) => {
          const blobWithMetadata = new Blob([newBuffer], { type: "image/jpeg" });
          const urlWithMetadata = window.URL.createObjectURL(blobWithMetadata);

          const a = document.createElement('a');
          a.href = urlWithMetadata;
          a.download = `${name}-${number}.${extension}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          setTimeout(() => {
            window.URL.revokeObjectURL(urlWithMetadata);
            setLoadingImages((prevImages) => prevImages.filter((imgNumber) => imgNumber !== number));
          }, 100);
        });
    };

    reader.readAsArrayBuffer(blob);
      
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error.message);
    }
  };
  
  const handleDownloadAll = async (specificIndex = null) => {
    const zip = new JSZip();
    const folder = zip.folder(name);

    if (specificIndex !== null) {
      setLoadingImages((prevImages) => [...prevImages, specificIndex+1]);
    } else {
      setIsDownloadingAll(true);
      setDownloadProgress(0);
    }

    let downloadedImagesCount = 0;
    
    const updateProgress = () => {
      downloadedImagesCount++;
      setDownloadProgress((downloadedImagesCount / totalItems) * 90);
    };

    // Determine the range of images to download
    const startIndex = specificIndex !== null ? specificIndex : 0;
    const endIndex = specificIndex !== null ? specificIndex + 1 : totalItems;
  
    const imagePromises = Array.from({ length: endIndex - startIndex }).map(async (_, index) => {
      return new Promise(async (resolve, reject) => {
        const imageNumber = startIndex + index + 1;
  
        try {
          const response = await fetch(`${baseUrl}/${imageNumber}.${extension}`);
          if (!response.ok) throw new Error('Failed to fetch image');
          
          const blob = await response.blob();
          const reader = new FileReader();
  
          reader.onloadend = async () => {
            let blobToUse = blob;
            try {
              const imageBuffer = Buffer.from(reader.result);
              const newBuffer = await addMetadataToImage(imageBuffer, new Date());
              blobToUse = new Blob([newBuffer], { type: "image/jpeg" });
            } catch (metadataError) {
              console.error('Failed to add metadata:', metadataError);
            }

            const now = new Date();
            // now.setMinutes(now.getMinutes() + 10);
            folder.file(`${name}-${imageNumber}.${extension}`, blobToUse, {date: now});
            updateProgress();
            resolve();
          };
  
          reader.readAsArrayBuffer(blob);
  
        } catch (error) {
          console.error(`Failed to download image ${imageNumber}:`, error);
          reject(error);
        }
      });
    });
  
    await Promise.all(imagePromises);
  
    zip.generateAsync({ type: 'blob' })
      .then(blob => {
        if (specificIndex !== null) {
          setLoadingImages((prevImages) => prevImages.filter((imgNumber) => imgNumber !== specificIndex+1));
        } 
        setDownloadProgress(100);
        console.log("FFFFFF", specificIndex)
        const zipName = specificIndex !== null ?  `${name}-${specificIndex+1}.zip` :  `${name}.zip`
        saveAs(blob, zipName);
        setIsDownloadingAll(false);
      })
      .catch(error => {
        console.error("Error generating zip:", error);
      });
  };
  
  

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);
  
  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      
      <VStack pb='8' height='100%' minHeight={{ base: 350, md: isDownloadingAll ? 'auto' : 500 }} justify='space-between'>
        {(!isDownloadingAll && totalItems > 1) &&
          <Flex justify='flex-end' width='100%'>
            <Button mt='4' size="sm" mb='6' bg={theme.primary} fontSize='xs' onClick={() => handleDownloadAll()}>Download All Clones</Button>
          </Flex>
        }
        
        {isDownloadingAll && (
          <Flex align='center' justify='center' flex={1} py='8'>
            <Box>
              <Text fontSize='xl' color='#fff' mb='6'><Text as='span' mr='3'><Spinner color={theme.primary} /></Text>Downloading All Clones...</Text>
              <Progress value={downloadProgress} rounded='full' colorScheme='yellow' />
            </Box>
          </Flex>
        )}
        
        {!isDownloadingAll &&
           <Flex align="center" justify="center" flex={1}>
           <Wrap spacing={4} spacingY={8} justify="center">
             {Array.from({ length: totalItems }).slice(startIdx, endIdx).map((_, index) => {
               const imageUrl = `${baseUrl}/${progress > -1 ? "thumbnail" : "original"}`;
               const imageNumber = index + startIdx + 1;
               return (
                 <WrapItem key={index + startIdx}>
                   <Flex direction="column" align="center">
                     <Text mb="2" fontSize="sm" fontWeight={500} color="#fff">
                       Clone {imageNumber}
                     </Text>
                     <Box position="relative" boxSize={{ base: "60px", md: "120px" }} boxShadow="sm" borderRadius="md" overflow="hidden" borderWidth={1} borderColor="#fff">
                       <Image objectFit="cover" src={imageUrl} alt={`Clone ${imageNumber}`} />
                       {loadingImages.includes(imageNumber) && (  // Check if the image number is in the array
                          <Box position="absolute" top="0" left="0" right="0" bottom="0" backgroundColor="rgba(255,255,255,0.7)" display="flex" justifyContent="center" alignItems="center">
                            <Spinner />
                          </Box>
                        )}
                     </Box>
                     <DownloadButton handleDownload={handleDownload} imageNumber={imageNumber} />
                   </Flex>
                 </WrapItem>
               );
             })}
           </Wrap>
         </Flex>
        }
        
        {(!isDownloadingAll && totalItems > itemsPerPage) &&
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        }
      </VStack>
    </>
  );
}

export default ViewOrder;
