import React, { useContext, useState } from "react";
import { Link as ReactLink } from 'react-router-dom';
import { Flex, Box, FormControl, FormLabel, Stack, Link, Button, Heading, Text } from '@chakra-ui/react';
import InlineError from "../../Components/InlineError/InlineError";
import MetaTag from "../../Components/MetaTag";
import Input from "Components/Input/Input";
import { CheckIcon } from "@chakra-ui/icons";
import { forgotPasswordAPI } from "../../lib/API/authAPI"
import Logo from "Images/Logo";
import { ThemeContext } from "Context/ThemeContext";

export default function ForgotPassword() {
  const { theme } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMesage] = useState("")

  const onForgotHandler = async e => {
    setLoading(true)
    e.preventDefault()
    e.stopPropagation()

    setSuccessMesage("")
    setErrorMessage("")
    setLoading(true)
    
    const res = await forgotPasswordAPI(email)

    if (res.data.success) setSuccessMesage(res.data.message)
    else setErrorMessage(res.data.message);
    
    setLoading(false)
  };

  return (
    <Flex minH={'100vh'} align={{ base: 'flex-start', md: 'center' }} justify='center' bgGradient={`linear(to-bl, ${theme.primary}, ${theme.gradient})`}>
      <MetaTag title="Forgot Password"/>
      <Stack spacing={8} py={12} px={6} mx='auto' width='100%' maxW={'lg'}>
        <Stack align={'center'}>
          <Heading fontSize={{ base: '3xl', md: '4xl' }} color='#fff'>Trouble signing in?</Heading>
        </Stack>

        <Box rounded={'2xl'} bg='white' boxShadow={'lg'} p={8} as='form' onSubmit={onForgotHandler}>
          <Stack spacing={4}>
            <Flex justify="center">
              <Logo isVariant isLink />
            </Flex>
            <Text color='gray.800' pb={2}>Enter the email address associated with your account, and we'll send you a link to reset your password.</Text>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" isEmail={true} onChange={(e) => setEmail(e.target.value)} value={email} />
            </FormControl>
            <Stack pt={4}>
              <Button type={"submit"} bg={theme.primary} color={'white'} _hover={{ bg: theme.hover }} isLoading={loading}>
                Request Reset
              </Button>
            </Stack>
            <InlineError text={errorMessage} />
            {successMessage && 
              <Flex align='center'>
                <CheckIcon mr={2} color='green.500' />
                <Text color='green.500'>{successMessage}</Text>
              </Flex>
            }
          </Stack>
        </Box>
        <Box textAlign='center'>
          <Link as={ReactLink} color='#fff' to='/auth/login' fontWeight={600}>Back to login</Link>
        </Box>
      </Stack>
    </Flex>
  );
}