const NODE_ENV = process.env.NODE_ENV;
var data;

// Init config_buffer according to the NODE_ENV
switch (NODE_ENV) {
    case 'test':
        if (NODE_ENV === 'dev') console.log("Using test config")
        data = require('./test.json')
        break;
    case 'staging':
        if (NODE_ENV === 'dev') console.log("Using staging config")
        data = require('./staging.json')
        break;
    case 'dev':
        if (NODE_ENV === 'dev') console.log("Using dev config")
        data = require('./dev.json')
        break;
    case 'production':
        if (NODE_ENV === 'dev') console.log("Using production config")
        data = require('./production.json')
        break;
    default:
        if (NODE_ENV === 'dev') console.log("Using dev config")
        data = require('./dev.json')
        break;
}

module.exports = data;