import React, { useState, useEffect, useContext } from "react";
import Sidebar from "Components/Sidebar/Sidebar";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack, 
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import { getOrdersAPI, addOrderAPI, getPreSignedUrlAPI } from "lib/API/orderAPI";
import OrderForm from "./Order/OrderForm";
import OrdersTable from "./Order/OrdersTable";
import CreditsBar from "./Order/CreditsBar";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdPayment } from "react-icons/md";
import { createPortalSessionAPI } from "lib/API/billingAPI";
import { toast } from "react-toastify";
import { BiLogOut } from "react-icons/bi";
import { logout } from "lib/helper/authHelper";
import { Link } from "react-router-dom";
import { ThemeContext } from "Context/ThemeContext";
import { Spinner } from '@chakra-ui/react';  
import GhostMatchBanner from "./GhostMatchBanner";

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const billing = useSelector((state) => state.billing);
  const { theme } = useContext(ThemeContext);

  const [visibleOrders, setVisibleOrders] = useState([]);
  const [hiddenOrders, setHiddenOrders] = useState([]);

  const onBillingClicked = async () => {      
    const res = await createPortalSessionAPI()

    if (res.data.success) {
      window.open(res.data.url, "_self")
    } else {
      toast.error(res.data.error)
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const cutoff = 100; // You can set this to any number you want

  const [hasMore, setHasMore] = useState(false); // New state variable

  useEffect(() => {
    async function fetchData() {
      const res = await getOrdersAPI(offset, cutoff * 2);
      const firstBatch = res.data.orders.slice(0, cutoff);
      const nextBatch = res.data.orders.slice(cutoff);

      if (res.data.orders.length < cutoff) {
        setHasMore(false);
      } else {
        setHasMore(true)
      }

      setVisibleOrders(firstBatch);
      setHiddenOrders(nextBatch);
      setOffset(offset + cutoff * 2);
    }

    fetchData();
  }, []);

  const loadMore = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
  
      if (hiddenOrders.length === 0) {
        const res = await getOrdersAPI(offset, cutoff * 2);
        const nextBatch = res.data.orders;
        const firstBatch = nextBatch.slice(0, cutoff);
        const nextBatchRemaining = nextBatch.slice(cutoff);
  
        // If fewer records are returned, set hasMore to false
        if (nextBatch.length < cutoff * 2) {
          setHasMore(false);
        }
  
        setVisibleOrders([...visibleOrders, ...firstBatch]);
        setHiddenOrders(nextBatchRemaining);
        setOffset(offset + cutoff * 2);
      } else {
        const newVisible = [...visibleOrders, ...hiddenOrders.slice(0, cutoff)];
        const newHidden = hiddenOrders.slice(cutoff);
  
        setVisibleOrders(newVisible);
        setHiddenOrders(newHidden);
      }
      
      setIsLoading(false);
    }
  }
  

  
  const onAddOrder = (name, id, quantity) => {
    const newOrder = { name, _id: id, quantity, status: "In Progress", date: new Date(), progress: -1 }

    setVisibleOrders([newOrder, ...visibleOrders])

    setOffset(offset + 1)
  }

  return (
    <Sidebar selectedTab={"Dashboard"} metaTagName="Dashboard">
      <Box>
        <Flex direction={{ base: 'column-reverse', md: 'row' }}>
          <Flex flexDirection='column' justify='space-between' flex={1}>
            <Box>
              <Flex justify='space-between' mb='8'>
                { billing.currentPlanId === 0 &&
                  <Button as={Link} to='/upgrade' colorScheme='green' bg='#00BF63' color='#fff' fontSize='sm' leftIcon={<MdPayment size={12} />} size="md">Upgrade</Button>
                }

              { billing.currentPlanId !== 0 &&
                <Button colorScheme='green' bg='#00BF63' color='#fff' fontSize='sm' leftIcon={<MdPayment size={12} />} onClick={() => onBillingClicked()} size="md">Billing</Button>
              }

                <Button colorScheme='red' variant='outline' borderColor='#FF5757' color='#FF5757' fontSize='sm' leftIcon={<BiLogOut size={12} />} onClick={() => logout()} size="md">Sign Out</Button>
              </Flex>
            </Box>

            <Box mb='8'>
              <CreditsBar />
            </Box>
          </Flex>

          {/* <Flex mb='8'>
            <GhostMatchBanner />
          </Flex> */}
        </Flex>

        <Box pb='6'>
          <Flex align='center' justify='space-between' mb='8'>
              <Text fontSize='2xl' color='#fff' fontWeight={600}>Your Orders</Text>
              <Button colorScheme='yellow' bg={theme.primary} color='#1a1a1a' fontSize='sm' leftIcon={<FaPlus size={12} />} onClick={onOpen} size="md">Create Order</Button>
            </Flex>

          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay backdropFilter='blur(10px) hue-rotate(90deg)' />
            <ModalContent bg='#1a1a1a'>
              <ModalHeader color='#fff'>Create Order</ModalHeader>
              <ModalCloseButton color='#fff' />
              <ModalBody>
                <OrderForm onClose={onClose} onAddOrder={onAddOrder}/>
              </ModalBody>
            </ModalContent>
          </Modal>

          {visibleOrders.length > 0 ?  
          <OrdersTable orders={visibleOrders} />
          : 
          <Flex align='center' justify='center' borderWidth={1} borderStyle='dashed' rounded='md'>
            <Text color='#fff' py='6'>No orders yet!</Text>
          </Flex>
        }
        </Box>

        { hasMore &&
          <Flex justifyContent="center" mb={8}>
            <Button 
              onClick={loadMore} 
              colorScheme='teal'  // Set color
              variant='outline'
              size='sm'
              color='white'  // Text color
              borderColor='gray.600'  // Border color
            >
              {isLoading ? <Spinner size="sm" color='white' /> : 'View More'}
            </Button>
          </Flex>
        }
      </Box>

    </Sidebar>
  );
}
