import { getAPIWrapper, postAPIWrapper, putAPIWrapper } from "./API";

export const authCheckAPI = (name, email, password) => {
    return postAPIWrapper("/api/auth/check", {fullName: name, email, password});
};

export const registerAPI = (name, email, password, fbp, fbc, referralId, formData) => {
    return postAPIWrapper("/api/auth/register", {fullName: name, email, password, fbp, fbc, referralId, formData});
};

export const loginAPI = (email, password) => {
    return postAPIWrapper("/api/auth/login", {email, password});
};

export const authenticateAccessToken = () => {
    return getAPIWrapper("/api/auth/authenticate_token");
};

export const getPreassignedUrlAPI = (filename, fileType) => {
    return postAPIWrapper("/api/auth/preassigned-url", { filename, fileType });
};

export const updateProfileImageAPI = key => {
    return postAPIWrapper("/api/auth/profile-image", { key });
};

export const updateUserAPI = (fullName) => {
    return postAPIWrapper("/api/auth/update", { fullName });
};

export const changePasswordAPI = (oldPassword, newPassword) => {
    return putAPIWrapper("/api/auth/password/change", { oldPassword, newPassword });
};

export const forgotPasswordAPI = email => {
    return postAPIWrapper("/api/auth/password/forgot", { email });
}

export const resetPasswordAPI = (password, token) => {
    return postAPIWrapper("/api/auth/password/reset", { password, token });
}

export const verifyEmailAPI = token => {
    return postAPIWrapper(`/api/auth//email-verification?token=${token}`);
}

export const unsubscribeAPI = (uid, type) => {
    return putAPIWrapper("/api/auth/unsubscribe", { uid, type });
}

export const updateBrandingAPI = (name, logoKey, themeColor, sidebarColor) => {
    return putAPIWrapper(`/api/auth/branding`, { name, logoKey, themeColor, sidebarColor });
};

export const getBrandingUrlAPI = (fileType) => {
    return getAPIWrapper(`/api/auth/branding/logo-url`, { fileType });
};

export const updateEmailPreferenceAPI = (type, email) => {
    return putAPIWrapper(`/api/user/email_preference`, { type, email });
};


export default {
    authCheckAPI,
    registerAPI,
    loginAPI,
    getPreassignedUrlAPI,
    updateProfileImageAPI,
    updateUserAPI,
    changePasswordAPI,
    forgotPasswordAPI,
    verifyEmailAPI,
    unsubscribeAPI,
    updateBrandingAPI,
    getBrandingUrlAPI,
    updateEmailPreferenceAPI
}