import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Flex, Icon, Image, List, ListIcon, ListItem, Text, VStack } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import Theme from "Theme";
import { MdCheck, MdCheckCircle } from "react-icons/md";
import { ThemeContext } from "Context/ThemeContext";

const gradients = [
  "radial-gradient(circle at top, #fb5cb8 , #63aee5)",
  "radial-gradient(circle at top, #8413a9 , #63aee5)",
  "radial-gradient(circle at top, #80f7e1 , #4393d1)",
  "radial-gradient(circle at top, #39f5fd , #63aee5)"
]

const CardImage = function({ src, maxWidth }) {
  return (
    <div>
       <Image src={require(`./Images/${src}`)} width="100%" rounded='2xl' maxWidth={maxWidth} />
    </div>
  )
}

export default function Card({ title, video, iconBgColor, icon, text, src, position, index, points }) {
  const { theme } = useContext(ThemeContext);
  const brand = Theme();
  const isLeft = position == "left";
  const isRight = position == "right";

  return (
    <Flex p={{ base: 0, md: 6 }} alignItems="center" direction={{ base: "column", md: "row" }} position="relative" zIndex={1} justify='space-around'>
      {isLeft && (
        <Box mr={12} display={{ base: "none", md: "block" }} boxShadow="md" data-aos="fade-left" position="relative" rounded='2xl'>
          <Image src={require(`Images/Landing/Cards/${src}`)} width="100%" rounded='2xl' position={"relative"} maxWidth={550} />
        </Box>
      )}

      <VStack maxWidth={500} textAlign="left" alignItems="flex-start" zIndex={1} data-aos={isLeft ? "fade-right" : "fade-left"}>
        <Box p='3' rounded='xl' bg='#1a1a1a' borderWidth={1} borderColor={theme.primary} mb='1' display={{ base: 'none', md: 'block' }}>
          <Icon as={icon} w={8} h={8} color={theme.primary} />
        </Box>
        
        <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={700} color={theme.primary}>{title}</Text>
        <Text color='gray.50' fontSize='md' pb={4} pr={{ base: 0, md: 2 }}>{text}</Text>

        <List spacing={3} textAlign='left' pb={5}>
          { points.map((point) => (
            <ListItem fontSize='md' fontWeight={400} color='gray.200'>
              <ListIcon as={MdCheck} color={theme.primary} w="5" h="5" />{point}
            </ListItem>
          ))}
        </List>
      </VStack>
    
      {isRight && (
        <Box ml={12} display={{ base: "none", md: "block" }} boxShadow="md" data-aos="fade-right" position="relative" rounded='2xl'>
          <Image src={require(`Images/Landing/Cards/${src}`)} width="100%" rounded='2xl' maxWidth={550} />
        </Box>
      )}
      

      <Box mt={6} display={{ base: "block", md: "none" }} boxShadow="md" position="relative" data-aos={isRight ? "fade-right" : "fade-left"}>
        <Image src={require(`Images/Landing/Cards/${src}`)} width="100%" rounded='2xl' />
      </Box>
    </Flex>
  );
}