import config from "lib/config/index"
import { types } from "./brandingActions";

export default function brandingReducer(state = [], {type, payload}){
    switch (type){
        case types.UPDATE_BRANDING: {
            const { brandColor, sidebarColor, name, key } = payload
            return {
                ...state,  
                brandColor,
                sidebarColor,
                name,
                key,
                logo: `${config.branding_image_domain}/${key ? key : "ghostclone.png"}?date=${Date.now()}`
            }
        }

        default:
            return state
    }
}