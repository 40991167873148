export const updateInitialCallRequest = () => {
    return {
        type: types.SET_INITIAL_CALL_REQUESTED,
        payload: {}
    }
}

export const updateInitialCallLoading = val => {
    return {
        type: types.UPDATE_INITIAL_CALL_LOADING,
        payload: { val }
    }
}

export const updateInitialCallError = error => {
    return {
        type: types.UPDATE_INITIAL_CALL_ERROR,
        payload: { error }
    }
}


export const addNotificationsAction = notifications => {
    return {
        type: types.ADD_NOTIFICATIONS,
        payload: { notifications }
    }
}

export const addNotificationAction = (id, title, description, imageSrc, webPath, read, date, clientName) => {
    return {
        type: types.ADD_NOTIFICATION,
        payload: { id, title, description, imageSrc, webPath, read, date, clientName }
    }
}

export const readNotificationAction  = (notificationId) => {
    return {
        type: types.READ_NOTIFICATION,
        payload: { notificationId }
    }
}

export const readAllNotificationsAction = () => {
    return {
        type: types.READ_ALL_NOTIFICATIONS,
        payload: {}
    }
}

export const updatePreferencesAction = data => {
    return {
        type: types.UPDATE_PREFERENCES,
        payload: data

    }
}

export const updatePreferenceAction = (type, email, notification) => {
    return {
        type: types.UPDATE_PREFERENCE,
        payload: { type, email, notification }
    }
}


export const types = {
    SET_INITIAL_CALL_REQUESTED: 'notification:updateInitialCallRequested',
    UPDATE_INITIAL_CALL_LOADING: 'notification:updateInitialCallLoading',
    UPDATE_INITIAL_CALL_ERROR: 'notification:updateInitialCallError',
    ADD_NOTIFICATIONS: 'notification:addNotifications',
    ADD_NOTIFICATION: 'notification:addNotification',
    READ_NOTIFICATION: 'notification:readNotification',
    READ_ALL_NOTIFICATIONS: 'notification:readAllNotifications',
    UPDATE_PREFERENCES: 'notification:updatePreferences',
    UPDATE_PREFERENCE: 'notification:updatePreference',
}

export default {
    updateInitialCallRequest,
    updateInitialCallLoading,
    updateInitialCallError,
    addNotificationsAction,
    readNotificationAction,
    readAllNotificationsAction,
    updatePreferencesAction,
    updatePreferenceAction,
    addNotificationAction
}

