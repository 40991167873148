// const notificationSound = new Audio('new-message.mp3');
// import history from "../../history";


export const isNotificationSupported = () => {
  if (!("Notification" in window))  return false
  
  return true
}

export const isNotificationDisabled = () => {
  if (!isNotificationSupported()) return null
  if (Notification.permission === "denied") return true
  
  return false
}

export const isNotificationGranted = () => {
  if (!isNotificationSupported()) return null

  if (Notification.permission === "granted") return true
  
  return false
}

export const requestNotificationPermission = async () => {
  if (!isNotificationSupported()) return null

  if (isNotificationDisabled()) return false

  const permission = await Notification.requestPermission()

  if (permission === "granted") {
      return true
  }

  return false
}

export const displayNotification = (title, message, path) => {
  if (!isNotificationSupported()) return null

  if (isNotificationGranted()){
      var notification = new Notification(title, {
          icon: 'https://ghostclone.io/favicon-32x32.png',
          // image: 'https://ecudate.com/assets/images/logo.png',
          body: message
      });

      // notificationSound.play()

      notification.onclick = function() {
          // parent.focus();
          window.focus(); //just in case, older browsers
          // if (path) history.replace(path);
          this.close();
      };
  }
}

