import React from 'react';
import { GridItem, Box, Image, Text, Flex, Link, AspectRatio } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

const BlogCard = ({ id, title, date, width }) => {
  return (
    <GridItem key={id}>
      {/* Use ReactLink from react-router-dom */}
      <Link as={ReactLink} to={`/blog/${id}`}>
        <Box p={4} overflow="hidden" borderRadius="lg" maxW={width}>
          {/* Set max width for each card */}
          <AspectRatio ratio={4 / 2}>
            <Image src={require(`./data/${id}/featureImage.jpg`)} alt={title} borderRadius="xl" objectFit="cover" />
          </AspectRatio>
          <Flex direction="column" mt={4}>
            <Text fontWeight="semibold" fontSize="lg" noOfLines={2}>
              {title}
            </Text>
            <Text mt={1} fontSize="sm" color="gray.600">
              {date}
            </Text>
          </Flex>
        </Box>
      </Link>
    </GridItem>
  );
};

export default BlogCard;
