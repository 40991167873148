import React from 'react';
import './spinner.css'


class Spinner extends React.Component {
    getCircleClass = (n) => {
        if (this.props.color === 'black') return "sk-circle" + n + " sk-circle black"
        return "sk-circle" + n + " sk-circle white"
    }
    render() {
        return (
            <div className="sk-fading-circle" style={{width: this.props.width, height: this.props.width, margin: this.props.margin}}>
                <div className={this.getCircleClass('1')}></div>
                <div className={this.getCircleClass('2')}></div>
                <div className={this.getCircleClass('3')}></div>
                <div className={this.getCircleClass('4')}></div>
                <div className={this.getCircleClass('5')}></div>
                <div className={this.getCircleClass('6')}></div>
                <div className={this.getCircleClass('7')}></div>
                <div className={this.getCircleClass('8')}></div>
                <div className={this.getCircleClass('9')}></div>
                <div className={this.getCircleClass('10')}></div>
                <div className={this.getCircleClass('11')}></div>
                <div className={this.getCircleClass('12')}></div>
            </div>
        )
    }
}

export default Spinner