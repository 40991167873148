import { types } from "../../actions/user/intialCallsActions";

export default function userInitialCallsReducer(state = [], {type, payload}){
    switch (type){
        case types.SET_REQUESTED_INITIAL_CALL: {
            const { key, value } = payload
            return {
                ...state,
                initialCalls: {
                    ...state.initialCalls,
                    [key]: {
                        ...state.initialCalls[key],
                        requested: value
                    }
                }
            }
        }

        case types.SET_LOADING_INITIAL_CALL: {
            const { key, value } = payload
            return {
                ...state,
                initialCalls: {
                    ...state.initialCalls,
                    [key]: {
                        ...state.initialCalls[key],
                        loading: value
                    }
                }
            }
        }

        case types.SET_ERROR_INITIAL_CALL: {
            const { key, value } = payload
            return {
                ...state,
                initialCalls: {
                    ...state.initialCalls,
                    [key]: {
                        ...state.initialCalls[key],
                        error: value
                    }
                }
            }
        }

        default:
            return state
    }
}